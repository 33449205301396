import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';

import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <li key="Facebook" className={css.listItem}>
      <ExternalLink
        key="linkToFacebook"
        href={siteFacebookPage}
        className={css.link}
        title={goToFb}
      >
        <IconSocialMediaFacebook />
        <FormattedMessage id="Footer.fbText" />
      </ExternalLink>
    </li>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <li key="Twitter" className={css.listItem}>
      <ExternalLink
        key="linkToTwitter"
        href={siteTwitterPage}
        className={css.link}
        title={goToTwitter}
      >
        <IconSocialMediaTwitter />
        <FormattedMessage id="Footer.twText" />
      </ExternalLink>
    </li>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <li key="Instagram" className={css.listItem}>
      <ExternalLink
        key="linkToInstagram"
        href={siteInstagramPage}
        className={css.link}
        title={goToInsta}
      >
        <IconSocialMediaInstagram />
        <FormattedMessage id="Footer.inText" />
      </ExternalLink>
    </li>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, isCurrentUserClient } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <span className={css.textLogo}>{config.siteTitle}</span>
              <div className={css.organizationInfo}>
                <p className={css.organizationCopyright}>
                  <span className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </span>
                </p>
                <p className={classNames(css.copyright, css.organizationCopyright)}>
                  &#169; {config.siteTitle}
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.companyTitle" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    About
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="PhotographersPage" className={css.link}>
                    For Photographers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" className={css.link}>
                    How it works
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink href="https://www.picturehummable.com" className={css.link}>
                    Blog
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="FAQPage"
                    params={{
                      id: 'cancellation-policy',
                    }}
                    className={css.link}
                  >
                    FAQ
                  </NamedLink>
                </li>

                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    Contact
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.photographersTitle" />
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=photographer',
                    }}
                    className={css.link}
                  >
                    Photographers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="SearchPage"
                    to={{
                      search: '?pub_category=mini_session',
                    }}
                    className={css.link}
                  >
                    Mini sessions
                  </NamedLink>
                </li>
                {isCurrentUserClient ? null : (
                  <li className={css.listItem}>
                    <NamedLink
                      name="NewListingPageWithCategory"
                      params={{
                        category: 'photographer',
                      }}
                      className={css.link}
                    >
                      Post a photo listing
                    </NamedLink>
                  </li>
                )}
                {isCurrentUserClient ? null : (
                  <li className={css.listItem}>
                    <NamedLink
                      name="NewListingPageWithCategory"
                      params={{
                        category: 'mini_session',
                      }}
                      className={css.link}
                    >
                      Post a mini sesh
                    </NamedLink>
                  </li>
                )}
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.legalTitle" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.socialTitle" />
                </li>
                {socialMediaLinks}
              </ul>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <span className={css.organizationCopyrightMobile}>&#169; {config.siteTitle}</span>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="LandingPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="LandingPage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isCurrentUserClient } = state.user;
  return {
    isCurrentUserClient,
  };
};

export default compose(
  connect(mapStateToProps),
  injectIntl
)(Footer);
