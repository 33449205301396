import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconArrowHead } from '..';

import css from './EditListingPayoutDetailsPanel.module.css';

const StripeBox = props => {
  // State
  const [showStripeForm, setShowStripeForm] = useState(false);

  // Props
  const { intl, children, currentUserLoaded, isStripeConnected } = props;

  // Translation messages
  const badgeText = isStripeConnected
    ? intl.formatMessage({
        id: 'EditListingPayoutDetailsPanel.connected',
      })
    : intl.formatMessage({
        id: 'EditListingPayoutDetailsPanel.notConnected',
      });

  // Classes
  const chevronClasses = classNames(css.stripeBoxChevron, {
    [css.stripeBoxChevronActive]: !!showStripeForm,
  });
  const badgeClasses = classNames(css.stripeBoxBadge, {
    [css.stripeBoxBadgeConnected]: isStripeConnected,
  });
  return (
    <div className={css.stripeBox}>
      <div className={css.stripeBoxHeader} onClick={() => setShowStripeForm(!showStripeForm)}>
        <h3 className={css.stripeBoxHeading}>
          {currentUserLoaded ? (
            <>
              <FormattedMessage id="EditListingPayoutDetailsPanel.stripeBoxHeading" />
              <span className={badgeClasses}>{badgeText}</span>
            </>
          ) : (
            <FormattedMessage id="EditListingPayoutDetailsPanel.stripeBoxLoading" />
          )}
        </h3>
        <IconArrowHead className={chevronClasses} direction="down" />
      </div>
      {showStripeForm ? <div className={css.stripeBoxContent}>{children}</div> : null}
    </div>
  );
};

export default StripeBox;
