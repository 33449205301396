import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import { FilterPopup, LocationAutosuggest } from '../../components';

import css from './LocationFilter.module.css';

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'subCategory';
};

class LocationFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
    this.shortKeywordTimeout = null;
    this.mobileInputRef = React.createRef();

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
    this.searchForLocation = this.searchForLocation.bind(this);

    this.state = {
      locationValue: '',
      submitted: false,
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  searchForLocation(value) {
    this.setState({
      locationValue: value.replace(',', ''),
      submitted: false,
      isFilterCleared: false,
    });
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      history,
      options,
      cancelFilters,
      filterConfig,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const urlParam = getKeywordQueryParam(queryParamNames);

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: initialValues[urlParam] };

    const handleSubmit = (params, isCancelFilterCleared) => {
      if (isCancelFilterCleared) {
        onSubmit({ [urlParam]: null });
        this.setState({
          submitted: true,
          isFilterCleared: false,
        });
      } else {
        onSubmit({ [urlParam]: this.state.locationValue });
        this.setState({
          isFilterCleared: true,
        });
      }
    };

    const schoolOptions = findOptionsForSelectFilter('cities', filterConfig);
    const schoolLabel = schoolOptions.find(s => {
      return s.key === initialValues[urlParam];
    });

    const labelForPopup = !!initialValues[urlParam]
      ? intl.formatMessage(
          { id: 'LocationFilter.labelSelected' },
          { labelText: (schoolLabel && schoolLabel.label) || initialValues[urlParam] }
        )
      : label;

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={!!initialValues[urlParam]}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        isCancelFilterCleared={true}
        keepDirtyOnReinitialize
        {...rest}
      >
        <LocationAutosuggest
          history={history}
          options={options}
          searchForLocation={this.searchForLocation}
          selectedSchool={this.state.locationValue}
          savedValue={initialValues[urlParam]}
          schoolOptions={schoolOptions}
          isFilterCleared={this.state.isFilterCleared}
          filterComponent
        />
      </FilterPopup>
    ) : null;
  }
}

LocationFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  filterConfig: config.custom.filters,
};

LocationFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(LocationFilter);
