import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  Tooltip,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;
const CUSTOM_ADDRESS_TOOLTIP_ID = 'CustomAddressTooltip';
const ADDRESS_TOOLTIP_ID = 'AddressTooltip';

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        isMiniSession,
        setPredictionAddress,
      } = formRenderProps;

      const titleRequiredMessage = isMiniSession
        ? intl.formatMessage({ id: 'EditListingLocationForm.address' })
        : intl.formatMessage({ id: 'EditListingLocationForm.cityAddress' });
      const addressPlaceholderMessage = isMiniSession
        ? intl.formatMessage({
            id: 'EditListingLocationForm.addressPlaceholder',
          })
        : intl.formatMessage({
            id: 'EditListingLocationForm.cityAddressPlaceholder',
          });
      const addressRequiredMessage = isMiniSession
        ? intl.formatMessage({
            id: 'EditListingLocationForm.addressRequired',
          })
        : intl.formatMessage({
            id: 'EditListingLocationForm.cityAddressRequired',
          });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const customAddressMessage = intl.formatMessage({
        id: 'EditListingLocationForm.customAddress',
      });
      const customAddressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.customAddressPlaceholder',
      });
      const customAddressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.customAddressRequired',
      });
      const customAddressRequired = required(customAddressRequiredMessage);

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {isMiniSession ? (
            <div className={css.customLocationAddress}>
              <div className={css.labelWithTooltip}>
                <label>{customAddressMessage}</label>
                <Tooltip
                  tipClass={css.tooltip}
                  iconClass={css.tooltipIcon}
                  id={CUSTOM_ADDRESS_TOOLTIP_ID}
                  message="Enter your custom address that will be used so that clients can see where you exactly operate"
                />
              </div>
              <FieldTextInput
                id="customAddress"
                name="customAddress"
                placeholder={customAddressPlaceholderMessage}
                validate={customAddressRequired}
              />
            </div>
          ) : null}

          <div className={css.customLocationAddress}>
            <div className={css.labelWithTooltip}>
              <label>{titleRequiredMessage}</label>
              <Tooltip
                tipClass={css.tooltip}
                iconClass={css.tooltipIcon}
                id={ADDRESS_TOOLTIP_ID}
                message="Select the address from suggestions that will be used while the clients are searching for specific address"
              />
            </div>
            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              autoFocus
              name="location"
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
              onSelectedPrediction={address => setPredictionAddress(address)}
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
