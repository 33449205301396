import React from 'react';
import Autosuggest from 'react-autosuggest';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import css from './LocationAutosuggest.module.css';

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0
    ? []
    : options.filter(lang => {
        return lang.label.toLowerCase().indexOf(inputValue) !== -1;
      });
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => {
  return suggestion.key;
};

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
  return <div>{suggestion.label}</div>;
};

class LocationAutosuggest extends React.Component {
  constructor(props) {
    super();
    const { savedValue } = props;

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: savedValue !== undefined ? savedValue : '',
      suggestions: [],
    };

    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  componentDidMount() {
    const { selectedSchool, isFilterCleared } = this.props;
    if (selectedSchool) {
      this.setState({
        value: selectedSchool,
      });
    }

    if (isFilterCleared) {
      this.onSuggestionsClearRequested();
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.options),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    const { searchForLocation, onAutomaticSelection, history, options } = this.props;
    const searchParam = options.find(o => o.key === suggestionValue);
    if (onAutomaticSelection) {
      history.push({
        pathname: '/s',
        search: '?pub_school=' + searchParam.key,
      });
    } else {
      searchForLocation(searchParam.key);
    }
  };

  render() {
    const { value, suggestions } = this.state;
    const { options, isFilterCleared, label, placeholder, className } = this.props;

    const finalValue = options.find(suggestion => suggestion.key === value)?.label;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder ? placeholder : 'Type a city or state',
      value: finalValue ? finalValue : value ? value : '',
      onChange: this.onChange,
    };

    const searchClasses = this.props.filterComponent ? css.filterSearch : css.search;
    return (
      <div className={classNames(className, searchClasses)}>
        <label>{label ? label : 'Search by city'}</label>
        <Autosuggest
          className={css.autoSuggest}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          inputProps={inputProps}
          theme={{
            input: css.autoSuggestInput,
            suggestionsContainer: css.autoSuggestContainer,
            suggestionsContainerOpen: css.autoSuggestContainerOpen,
            suggestionsList: css.autoSuggestList,
            suggestion: css.autoSuggest,
            suggestionHighlighted: 'react_autosuggest__suggestion__highlighted',
          }}
        />
      </div>
    );
  }
}

export default withRouter(LocationAutosuggest);
