import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar } from '../../components';

import IconCamera from './IconCamera';
import css from './ReviewsCustomCard.module.css';

const ReviewsCustomCard = props => {
  const { rootClassName, className, review } = props;

  if (!review) {
    throw new Error("Review doesn't exist on the marketplace.");
  }

  // Destruct the review
  const { content, rating, authorDisplayName, subjectDisplayName, image: reviewImage } = review;

  // As we are always showing 5 stars and then filling them
  // depending on the rating, we need to create an 5 keys array
  const newStarReviewArray = [...Array(5).keys()];

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.aspectWrapper}>
        <img className={css.image} src={reviewImage} alt={subjectDisplayName} />
      </div>
      <div className={css.info}>
        <p className={css.text}>{content}</p>
        <div className={css.heading}>
          <div className={css.stars}>
            {newStarReviewArray.map(star => {
              return <IconReviewStar key={star} className={css.star} isFilled={star < rating} />;
            })}
          </div>
          <span>{authorDisplayName}</span>
        </div>
        <p className={css.shootedBy}>
          <IconCamera className={css.cameraIcon} />
          <FormattedMessage
            id="ReviewsCustomCard.subjectDisplayName"
            values={{ subjectDisplayName }}
          />
        </p>
      </div>
    </div>
  );
};

ReviewsCustomCard.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReviewsCustomCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReviewsCustomCard;
