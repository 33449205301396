import React, { useState } from 'react';
import { NamedRedirect, Button } from '../../components';
import { LISTING_PAGE_PARAM_TYPE_DRAFT, createSlug } from '../../util/urlHelpers';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import axios from 'axios';

import css from './MiniSessionPaymentForm.module.css';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'poppins, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.1px',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const MiniSessionPaymentForm = ({
  currentListingIsPublishable,
  currentListingId,
  currentListing,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [name, setName] = useState('');
  const [postal, setPostal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [success, setSuccess] = useState(null);
  const currentListingSlug = currentListing ? createSlug(currentListing?.attributes?.title) : '';

  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,
        address: {
          postal_code: postal,
        },
      },
    });

    if (payload.error) {
      setErrorMessage(payload.error.message);
    } else {
      const { id } = payload.paymentMethod;
      try {
        const response = await axios.post('/confirm-payment', { id, currentListingId });
        setErrorMessage(null);
        setSuccess(true);
      } catch (e) {
        setErrorMessage(e?.response?.data?.message);
      }
    }
    setSubmitting(false);
  };

  const submitDisabled = !stripe || !name || !postal || submitting;

  return success || currentListingIsPublishable ? (
    <NamedRedirect
      name="EditListingPage"
      params={{
        category: 'mini_session',
        slug: currentListingSlug,
        type: LISTING_PAGE_PARAM_TYPE_DRAFT,
        id: currentListingId,
        tab: 'location',
      }}
    />
  ) : (
    <div className={css.root}>
      <form onSubmit={handleSubmit} className={css.form}>
        <div className={css.field}>
          <label htmlFor="name">Full Name</label>
          <input
            id="name"
            required
            placeholder="Jenny Rosen"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className={css.field}>
          <label htmlFor="cardNumber">Card Number</label>
          <CardNumberElement
            id="cardNumber"
            className={css.stripeElement}
            options={ELEMENT_OPTIONS}
          />
        </div>
        <div className={css.field}>
          <label htmlFor="expiry">Card Expiration</label>
          <CardExpiryElement id="expiry" className={css.stripeElement} options={ELEMENT_OPTIONS} />
        </div>
        <div className={css.field}>
          <label htmlFor="cvc">CVC</label>
          <CardCvcElement id="cvc" className={css.stripeElement} options={ELEMENT_OPTIONS} />
        </div>
        <div className={css.field}>
          <label htmlFor="postal">Postal Code</label>
          <input
            id="postal"
            required
            placeholder="90001"
            value={postal}
            onChange={e => {
              setPostal(e.target.value);
            }}
          />
        </div>

        <span className={css.smallPrint}>After paying you'll be able to proceed</span>
        <Button className={css.submitButton} type="submit" disabled={submitDisabled}>
          {submitting ? 'Paying...' : 'Pay'}
        </Button>
      </form>
      {errorMessage && <p className={css.error}>{errorMessage}</p>}
    </div>
  );
};

export default MiniSessionPaymentForm;
