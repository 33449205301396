import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Carousel, ReviewsCard, ContentLoader } from '../../components';

import css from './SectionReviews.module.css';

// Carousel setup
const INFINITE_LOOP = true;
const IS_LANDING_PAGE = true;

const SectionReviews = props => {
  const { rootClassName, className, reviews, fetchReviewsInProgress, fetchReviewsError } = props;

  // Declare when review sections should
  // be visible
  const showReviews = !fetchReviewsInProgress && !fetchReviewsError;
  const hasReviews = reviews?.length > 0;

  const classes = classNames(rootClassName || css.root, className);

  return showReviews ? (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionReviews.title" />
      </div>
      <div className={css.reviews}>
        <Carousel
          showCarousel={showReviews && hasReviews}
          landingPage={IS_LANDING_PAGE}
          infiniteLoop={INFINITE_LOOP}
        >
          {reviews.map(review => {
            const reviewId = review?.id?.uuid;
            return review ? <ReviewsCard key={reviewId} review={review} /> : null;
          })}
        </Carousel>
      </div>
    </div>
  ) : (
    <div className={css.reviewsLoader}>
      <ContentLoader height={340} width={440} />
    </div>
  );
};

SectionReviews.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionReviews.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionReviews;
