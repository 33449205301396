import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';
import { NewsletterModalForm } from '../../forms';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import css from './NewsletterModal.module.css';

const MAILCHIMP_API_URL = process.env.REACT_APP_MAILCHIMP_API;
const NEWSLETTER_MODAL_TIMEOUT = 3500;

const validateStatus = (currStatus, statusMessage) => {
  return currStatus === statusMessage;
};

const MailChimpForm = props => {
  const { onSubmitNewsletter } = props;
  return (
    <MailchimpSubscribe
      url={MAILCHIMP_API_URL}
      render={({ subscribe, status, message }) => {
        // Declare status messages
        const pendingStatus = validateStatus(status, 'sending');
        const successStatus = validateStatus(status, 'success');
        const errorStatus = validateStatus(status, 'error');

        const handleSubmit = values => {
          const { email } = values;

          const formData = {
            EMAIL: email,
          };

          // Notify parent about the submission
          onSubmitNewsletter();

          // Subscribe the user
          subscribe(formData);
        };
        return (
          <NewsletterModalForm
            onSubmit={handleSubmit}
            newsletterMessage={message}
            newsletterSuccess={successStatus}
            newsletterError={errorStatus}
            inProgress={pendingStatus}
          />
        );
      }}
    />
  );
};

const NewsletterModal = props => {
  const { className, rootClassName, id, intl, onManageDisableScrolling } = props;

  const [isNewsletterSubmitted, markNewsletterSubmitted] = useState(false);
  const [isNewsletterModalOpen, toggleNewsletterModal] = useState(false);

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.showNewsletterModal5 !== '1') {
      setTimeout(() => {
        toggleNewsletterModal(true);
      }, [NEWSLETTER_MODAL_TIMEOUT]);
    }
  }, []);

  const onSubmitNewsletter = () => {
    const showAfter = 30;
    saveNewsletterCookie(showAfter);
    markNewsletterSubmitted(true);
  };

  const onCloseNewsletterModal = () => {
    toggleNewsletterModal(false);

    // We need to ensure that after user submitted
    // the form and closes the modal the cookie isn't
    // updated
    if (!isNewsletterSubmitted) {
      const showAfter = 1;
      saveNewsletterCookie(showAfter);
    }
  };

  const saveNewsletterCookie = numberOfDays => {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + numberOfDays);
    // Save the cookie with expiration date
    document.cookie = 'showNewsletterModal5=1; path=/; expires=' + expirationDate.toGMTString();
  };

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'NewsletterModal.later' });
  const imageAlt = intl.formatMessage({
    id: 'NewsletterModal.imageAlt',
  });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isNewsletterModalOpen}
      onClose={onCloseNewsletterModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="NewsletterModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="NewsletterModal.description" />
      </p>
      <div className={css.modalImage} />
      <MailChimpForm onSubmitNewsletter={onSubmitNewsletter} />
    </Modal>
  );
};

const { string } = PropTypes;

NewsletterModal.defaultProps = {
  className: null,
  rootClassName: null,
};

NewsletterModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(NewsletterModal);
