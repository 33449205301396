import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import classNames from 'classnames';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  StaticPageHeader,
  NamedLink,
  NamedRedirect,
} from '../../components';
import { articles } from './faqArticles';
import truncate from 'lodash/truncate';

import css from './FAQPage.module.css';

const TITLE_COLLAPSED_LENGTH = 50;

const truncated = s => {
  return truncate(s, {
    length: TITLE_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const FAQPage = props => {
  const { params } = props;
  const { id } = params;

  // Find current article
  const currentArticle = articles.find(article => id === article.key);

  // If params id doesn't match with the article id
  // redirect to the NotFoundPage
  if (!currentArticle) {
    return <NamedRedirect name="NotFoundPage" />;
  }

  // Destruct the current article
  const { title, html } = currentArticle;

  return (
    <StaticPage
      title="FAQ"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        description: 'Everything you need to know',
        name: 'FAQPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FAQPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <StaticPageHeader
            title={
              <span>
                Everything you need
                <br />
                to know
              </span>
            }
            activePage={4}
          />
          <div className={css.staticPageWrapper}>
            <div className={css.contentWrapper}>
              <div className={css.contentSide}>
                <h4 className={css.contentSideTitle}>Articles</h4>
                <ul className={css.contentSideList}>
                  {articles?.map((article, index) => {
                    const truncatedTitle = truncated(article.title);
                    const activeArticle = article.key === id;
                    return (
                      <li key={article.key}>
                        <NamedLink
                          className={classNames(css.contentSideLink, {
                            [css.activeLink]: activeArticle,
                          })}
                          name="FAQPage"
                          params={{
                            id: article.key,
                          }}
                        >
                          {`${index + 1}. ${truncatedTitle}`}
                        </NamedLink>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={css.contentMain}>
                <h3 className={css.pageTitle}>{title}</h3>
                {html}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FAQPage;
