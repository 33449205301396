import React from 'react';
import { func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { required, composeValidators, checkValidWebsite } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';

import css from '../EditListingDescriptionForm/EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;
const OwnWebsiteFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
      } = formRenderProps;

      const websiteMessage = intl.formatMessage({ id: 'EditListingAvailabilityPanel.website' });
      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.websitePlaceholder',
      });
      const websiteRequiredMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.websiteRequired',
      });

      const invalidWebsiteMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.websiteInvalidMessage',
      });

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.ownWebsite}>
            <FieldTextInput
              id="website"
              name="website"
              className={css.title}
              type="text"
              label={websiteMessage}
              placeholder={websitePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(
                required(websiteRequiredMessage),
                checkValidWebsite(invalidWebsiteMessage)
              )}
              autoFocus
            />
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

OwnWebsiteFormComponent.defaultProps = { className: null, fetchErrors: null };

OwnWebsiteFormComponent.propTypes = {
  onSubmit: func.isRequired,
};

export default compose(injectIntl)(OwnWebsiteFormComponent);
