/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const signup = (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  const login = (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const signupOrLogin = (
    <span className={css.authenticationLinks}>
      <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
    </span>
  );

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      {isAuthenticated ? <AvatarLarge className={css.avatar} user={currentUser} /> : null}
      <div className={css.content}>
        {isAuthenticated ? (
          <>
            <span className={css.greeting}>
              <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
            </span>
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </>
        ) : (
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        )}
        <NamedLink
          className={classNames(css.topNavigationLink, currentPageClass('HowItWorksPage'), {
            [css.unloggedTopLink]: !isAuthenticated,
          })}
          name="HowItWorksPage"
          to={{
            search: '?pub_category=mini_session',
          }}
        >
          <FormattedMessage id="TopbarMobileMenu.howItWorksLink" />
        </NamedLink>
        <NamedLink
          className={css.navigationLink}
          name="SearchPage"
          to={{
            search: '?pub_category=photographer',
          }}
        >
          <FormattedMessage id="TopbarMobileMenu.findPhotographerLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('FAQPage'))}
          name="PhotographersPage"
        >
          <FormattedMessage id="TopbarMobileMenu.forPhotographersLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, css.miniSessionsLink)}
          name="SearchPage"
          to={{
            search: '?pub_category=mini_session',
          }}
        >
          <FormattedMessage id="TopbarMobileMenu.miniSessionLink" />
        </NamedLink>

        {isAuthenticated ? (
          <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.myListings" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
            >
              <FormattedMessage id="TopbarMobileMenu.inboxLink" />
              {notificationCountBadge}
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </NamedLink>
          </>
        ) : null}
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="SelectCategoryPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
