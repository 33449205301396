import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Carousel, ReviewsCustomCard } from '../../components';
import config from '../../config';

import css from './SectionCustomReviews.module.css';

// Carousel setup
const INFINITE_LOOP = true;
const IS_LANDING_PAGE = true;

const SectionCustomReviews = props => {
  const { rootClassName, className, customReviews } = props;

  // Declare when review sections should
  // be visible
  const hasReviews = customReviews?.length > 0;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionCustomReviews.title" />
      </div>
      <div className={css.reviews}>
        <Carousel
          showCarousel={hasReviews}
          landingPage={IS_LANDING_PAGE}
          infiniteLoop={INFINITE_LOOP}
        >
          {customReviews?.map((review, index) => {
            return review ? <ReviewsCustomCard key={index} review={review} /> : null;
          })}
        </Carousel>
      </div>
    </div>
  );
};

SectionCustomReviews.defaultProps = {
  rootClassName: null,
  className: null,
  customReviews: config.custom.landingPageReviews,
};

const { string, array } = PropTypes;

SectionCustomReviews.propTypes = {
  rootClassName: string,
  className: string,
  customReviews: array,
};

export default SectionCustomReviews;
