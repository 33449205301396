import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PayOrApplyCouponPage from '../../containers/PayOrApplyCouponPage/PayOrApplyCouponPage';

function MiniSessionPayout(props) {
  const { location, publicData, currentListing } = props;

  const publishable = useMemo(() => {
    if (typeof location?.state?.publishable !== 'undefined') {
      return location?.state?.publishable;
    }
    return currentListing?.attributes?.metadata?.publishable;
  }, [currentListing, location]);

  if (!publishable && publicData?.category === 'mini_session') {
    return (
      <PayOrApplyCouponPage
        currentListing={currentListing}
        currentListingId={currentListing.id.uuid}
      />
    );
  }
  return null;
}

export default withRouter(MiniSessionPayout);
