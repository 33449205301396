import React from 'react';
import { compose } from 'redux';
import { shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import { withViewport } from '../../util/contextHelpers';
import { IconArrowHead } from '../../components';
import { HeroForm } from '../../forms';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';

import HeroCarousel from './HeroCarousel';
import css from './SectionHero.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionHeroComponent = props => {
  const {
    rootClassName,
    className,
    currentSearchParams,
    history,
    location,
    viewport,
    introId,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const { address, origin, bounds } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Submit handler that is used once user
  // selects the location from the prediction
  // box
  const handleSubmit = values => {
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  // Scroll to intro section
  // on LandingPage
  const scrollToIntro = () => {
    const el = document.querySelector(`#${introId}`);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  };

  const locationFieldsPresent = config.sortSearchByDistance
    ? address && origin && bounds
    : address && bounds;
  const initialSearchFormValues = {
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
      : null,
  };

  const search = (
    <HeroForm
      className={css.searchLink}
      onSubmit={handleSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const heroTitleBreak = isMobileLayout ? ' ' : <br />;

  return (
    <div className={classes}>
      <HeroCarousel intl={intl} />
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" values={{ break: heroTitleBreak }} />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div className={css.search}>{search}</div>
      </div>
      <div className={css.arrowIconContainer} onClick={scrollToIntro}>
        <IconArrowHead className={css.arrowIcon} direction="down" />
      </div>
    </div>
  );
};

SectionHeroComponent.defaultProps = { rootClassName: null, className: null, introId: null };

SectionHeroComponent.propTypes = {
  rootClassName: string,
  className: string,
  introId: string,

  // These are passed from Page to keep SectionHero rendering aware of location changes
  location: shape({
    search: string.isRequired,
  }).isRequired,
};

const SectionHero = compose(withViewport)(SectionHeroComponent);

export default SectionHero;
