import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-san-diego',
    predictionPlace: {
      address: 'San Diego, California, USA',
      bounds: new LatLngBounds(
        new LatLng(34.01256428857857, -115.83563214669093),
        new LatLng(32.21593372033953, -117.98068779560526)
      ),
    },
  },
  {
    id: 'default-los-angeles',
    predictionPlace: {
      address: 'Los Angeles, California, USA',
      bounds: new LatLngBounds(
        new LatLng(35.23562137171143, -117.06736547599598),
        new LatLng(33.43899080347239, -119.24321267893)
      ),
    },
  },
  {
    id: 'default-maryland',
    predictionPlace: {
      address: 'Maryland DC, Virginia, USA',
      bounds: new LatLngBounds(
        new LatLng(40.00826318111608, -75.64405879392392),
        new LatLng(38.21163261287704, -77.95955537064587)
      ),
    },
  },
  {
    id: 'default-seattle',
    predictionPlace: {
      address: 'Seattle, Washington, USA',
      bounds: new LatLngBounds(
        new LatLng(47.7779392908564, -122.216605992108),
        new LatLng(47.3403950185547, -123.56014198384364)
      ),
    },
  },
  {
    id: 'default-bayarea',
    predictionPlace: {
      address: 'Bay Area, California, USA',
      bounds: new LatLngBounds(
        new LatLng(38.86424483191087, -121.2081780273586),
        new LatLng(36.89415492919909, -123.6324969552935)
      ),
    },
  },
  {
    id: 'default-minnesota',
    predictionPlace: {
      address: 'Minnesota, USA',
      bounds: new LatLngBounds(
        new LatLng(49.3843580459525, -89.48381217787039),
        new LatLng(43.49936096179093, -97.23919590360306)
      ),
    },
  },
  {
    id: 'salt-lake-city',
    predictionPlace: {
      address: 'Salt Lake City, Utah, USA',
      bounds: new LatLngBounds(
        new LatLng(41.7512852098358, -110.55178764096866),
        new LatLng(39.95465464159676, -112.92712852848516)
      ),
    },
  },
  {
    id: 'chicago',
    predictionPlace: {
      address: 'Chicago, Illinois, USA',
      bounds: new LatLngBounds(
        new LatLng(42.9214463018034, -86.31437862601541),
        new LatLng(41.12481573356436, -88.7329433240541)
      ),
    },
  },
  {
    id: 'atlanta',
    predictionPlace: {
      address: 'Atlanta, Georgia, USA',
      bounds: new LatLngBounds(
        new LatLng(34.78593316289061, -83.20723454277923),
        new LatLng(32.98930259465157, -85.37154341075033)
      ),
    },
  },
  {
    id: 'boston',
    predictionPlace: {
      address: 'Boston, Massachusetts, USA',
      bounds: new LatLngBounds(
        new LatLng(43.29913517199572, -69.53291873401287),
        new LatLng(41.502504603756684, -71.9659913048676)
      ),
    },
  },
  {
    id: 'miami',
    predictionPlace: {
      address: 'Miami, Florida, USA',
      bounds: new LatLngBounds(
        new LatLng(26.754088247529413, -79.14090252937265),
        new LatLng(24.957457679290368, -81.13741155930941)
      ),
    },
  },
  {
    id: 'philadelphia',
    predictionPlace: {
      address: 'Philadelphia, Pennsylvania, USA',
      bounds: new LatLngBounds(
        new LatLng(41.03630714831634, -73.78068330599993),
        new LatLng(39.2396765800773, -76.13084252009332)
      ),
    },
  },
  {
    id: 'phoenix',
    predictionPlace: {
      address: 'Phoenix, Arizona, USA',
      bounds: new LatLngBounds(
        new LatLng(34.81888532150106, -110.84347315922787),
        new LatLng(33.02225475326202, -113.00861885169593)
      ),
    },
  },
];
export default defaultLocations;
