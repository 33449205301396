import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, ExpandingTextarea, IconCopy, IconAdd } from '../../components';
import ReactTooltip from 'react-tooltip';

import css from './FieldTextInput.module.css';

const CONTENT_MAX_LENGTH = 5000;

class FieldTextInputComponent extends Component {
  constructor(props) {
    super(props);

    this.innerHover = this.innerHover.bind(this);
    this.innerHoverLeave = this.innerHoverLeave.bind(this);
    this.isCopied = this.isCopied.bind(this);
    this.copyHover = this.copyHover.bind(this);
    this.state = {
      isInnerHovered: false,
      isCopied: false,
    };
  }
  innerHover() {
    this.setState({
      isInnerHovered: true,
    });
  }
  innerHoverLeave() {
    this.setState({
      isInnerHovered: false,
    });
  }
  isCopied() {
    this.setState({
      isCopied: true,
    });
  }
  copyHover() {
    if (this.state.isCopied === true) {
      this.setState({
        isCopied: false,
      });
    }
  }
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      innerText,
      placeholder,
      infoTooltipMessage,
      copyToClipboard,
      infoText,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const isTextarea = input.type === 'textarea';

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};
    // Inner text hover class
    const innerHoverClass = this.state.isInnerHovered ? css.inputInnerHovered : null;
    const clipboardClass = copyToClipboard ? css.copyToClipboardInput : null;
    const inputClasses =
      inputRootClass ||
      classNames(innerHoverClass, clipboardClass, css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.textarea]: isTextarea,
      });
    const maxLength = CONTENT_MAX_LENGTH;
    const inputProps = isTextarea
      ? {
          className: inputClasses,
          id,
          rows: 1,
          maxLength,
          placeholder: placeholder,
          ...refMaybe,
          ...inputWithoutType,
          ...rest,
        }
      : isUncontrolled
      ? {
          className: inputClasses,
          id,
          type,
          defaultValue,
          placeholder: placeholder,
          ...refMaybe,
          ...inputWithoutValue,
          ...rest,
        }
      : {
          className: inputClasses,
          id,
          placeholder: placeholder,
          type,
          ...refMaybe,
          ...input,
          ...rest,
        };

    const classes = classNames(rootClassName || css.root, className);
    const copyMessage = this.state.isCopied ? 'Copied!' : 'Copy to clipboard';

    const inner = innerText ? (
      <div className={css.copyToClipboard}>
        <div className={css.innerContainer}>
          <input ref={inputFocus => (this.activate = inputFocus)} {...inputProps} />
          <span
            onClick={() => {
              this.activate.focus();
            }}
            onMouseEnter={this.innerHover}
            onMouseLeave={this.innerHoverLeave}
            className={classNames(css.innerText, inputClasses)}
          >
            {innerText}
          </span>
          <span
            className={css.copy}
            onMouseEnter={this.copyHover}
            onClick={() => navigator.clipboard.writeText(`${innerText}${input.value}`)}
            data-for="copy"
            data-tip=""
            onMouseLeave={this.resetCopyToClipboard}
          >
            <IconCopy />
            <ReactTooltip
              className={css.copyTooltip}
              id="copy"
              uuid="copy"
              place="right"
              getContent={() => {
                return null;
              }}
            >
              {copyMessage}
            </ReactTooltip>
          </span>
        </div>
      </div>
    ) : copyToClipboard ? (
      <div className={css.copyToClipboard}>
        <input {...inputProps} readOnly />
        <span
          className={css.copy}
          onMouseEnter={this.copyHover}
          onClick={() => navigator.clipboard.writeText(input.value)}
          data-for="copy"
          data-tip=""
          onMouseLeave={this.resetCopyToClipboard}
        >
          <IconCopy />
          <ReactTooltip
            className={css.copyTooltip}
            id="copy"
            uuid="copy"
            place="right"
            getContent={() => {
              return null;
            }}
          >
            {copyMessage}
          </ReactTooltip>
        </span>
      </div>
    ) : (
      <input {...inputProps} />
    );
    return (
      <div className={classes}>
        {label ? (
          <label htmlFor={id}>
            {label}
            {infoTooltipMessage ? (
              <span className={css.infoTooltip}>
                <span data-for="infoTooltip" data-tip="Message">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 14 14"
                  >
                    <g
                      fill="none"
                      stroke="#ffc20b"
                      strokeWidth="1.3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="7" cy="7" r="6.5"></circle>
                      <path d="M7 7L7 10.5"></path>
                      <circle cx="7" cy="4.5" r="0.5"></circle>
                    </g>
                  </svg>
                </span>
                <ReactTooltip className={css.infoTooltip} id="infoTooltip" place="right">
                  {infoTooltipMessage}
                </ReactTooltip>
              </span>
            ) : null}
          </label>
        ) : null}
        {infoText ? <p className={css.infoText}>{infoText}</p> : null}
        {isTextarea ? <ExpandingTextarea {...inputProps} /> : inner}
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldTextInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldTextInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldTextInput extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldTextInputComponent} {...this.props} />;
  }
}

export default FieldTextInput;
