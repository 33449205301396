import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { showListing } from '../../containers/ListingPage/ListingPage.duck';
import { storableError } from '../../util/errors';

export const GET_LISTING_REQUEST = 'app/LandingPage/GET_LISTING_REQUEST';
export const GET_LISTING_SUCCESS = 'app/LandingPage/GET_LISTING_SUCCESS';
export const GET_LISTING_ERROR = 'app/LandingPage/GET_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  getListingInProgress: false,
  getListingError: null,
  vanityId: null,
};

const listingPageVanityReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LISTING_REQUEST: {
      return {
        ...state,
        getListingInProgress: true,
        getListingError: null,
      };
    }
    case GET_LISTING_SUCCESS: {
      return {
        ...state,
        getListingInProgress: false,
        getListingError: null,
        vanityId: payload.listingId,
      };
    }
    case GET_LISTING_ERROR: {
      return {
        ...state,
        getListingInProgress: false,
        getListingError: payload,
      };
    }

    default:
      return state;
  }
};

export default listingPageVanityReducer;

// ================ Action creators ================ //

export const getListingRequest = () => ({
  type: GET_LISTING_REQUEST,
});
export const getListingSuccess = listing => ({
  type: GET_LISTING_SUCCESS,
  payload: { listingId: listing.id ? listing.id : null },
});
export const getListingError = e => ({
  type: GET_LISTING_ERROR,
  error: true,
  payload: e,
});

export const getListing = params => (dispatch, getState, sdk) => {
  dispatch(getListingRequest());
  const { slug } = params;

  return sdk.listings
    .query({ pub_slug: slug })
    .then(response => {
      const listings = response.data.data;

      const hasListings = listings.length > 0;
      const firstListing = hasListings ? listings[0] : null;

      dispatch(addMarketplaceEntities(response));
      dispatch(getListingSuccess(firstListing));

      if (firstListing) {
        dispatch(showListing(firstListing.id.uuid));
      }

      return firstListing;
    })
    .catch(e => dispatch(getListingError(storableError(e))));
};

export const loadData = params => dispatch => {
  return dispatch(getListing(params));
};
