import React from 'react';
import { string } from 'prop-types';

const IconImages = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="60"
      width="60"
      viewBox="0 0 24 24"
      fill="#f19b8c"
      stroke="#f19b8c"
      {...props}
    >
      <g fill="none" stroke="inherit" strokeLinejoin="round" strokeMiterlimit={10}>
        <path className="prefix__a" d="M23.5 5.5v17a1 1 0 01-1 1h-17" />
        <path className="prefix__a" d="M21.5 2.5v18a1 1 0 01-1 1h-18" />
        <rect className="prefix__a" x={0.5} y={0.5} width={19} height={19} rx={1} ry={1} />
        <path
          className="prefix__a"
          d="M17 15.5l-3.553-7.106c-.246-.492-.648-.492-.894 0L10 13.5l-1.86-2.232a.666.666 0 00-1.154.089L4.5 15.5"
        />
        <circle className="prefix__a" cx={6} cy={5} r={1.5} />
        <path className="prefix__a" d="M19.5 15.5H.5" />
      </g>
    </svg>
  );
};

IconImages.defaultProps = {
  className: null,
};

IconImages.propTypes = {
  className: string,
};

export default IconImages;
