import React from 'react';
import { string } from 'prop-types';

const IconYelp = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 435.646 435.646"
    >
      <g fill="#dc0000">
        <path d="M182.47 306.937l-60.235 73.351c-8.475 10.32-4.426 25.905 8 30.796l58.889 23.178c12.704 5 26.514-4.141 26.875-17.789l2.539-96.083c.512-19.369-23.772-28.426-36.068-13.453zM172.459 234.019l-89.331-32.073c-12.569-4.513-25.886 4.539-26.315 17.887l-2.033 63.253c-.439 13.646 12.813 23.58 25.788 19.331l91.345-29.907c18.414-6.028 18.781-31.944.546-38.491zM274.839 244.311l91.502-25.22c12.874-3.548 18.549-18.617 11.214-29.777l-34.762-52.883c-7.499-11.408-24.054-11.899-32.215-.954l-57.458 77.052c-11.583 15.532 3.041 36.931 21.719 31.782zM208.574 24.431C209.302 8.605 194.389-3.334 179.105.84l-72.428 19.78c-15.625 4.267-22.261 22.761-12.914 35.989l83.116 143.998c.675 1.169 1.419 2.302 2.294 3.331 12.309 14.465 35.417 9.105 41.113-7.828 1.023-3.042 1.311-6.284 1.059-9.484L208.574 24.431zM366.966 310.135l-91.883-28.213c-18.521-5.687-33.759 15.279-22.631 31.14l54.511 77.7c7.669 10.932 23.77 11.211 31.813.551l38.12-50.516c8.224-10.898 3.121-26.654-9.93-30.662z" />
      </g>
    </svg>
  );
};

IconYelp.defaultProps = {
  className: null,
};

IconYelp.propTypes = {
  className: string,
};

export default IconYelp;
