import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconDiscount } from '../../components';
import * as validators from '../../util/validators';

import css from './DiscountForm.module.css';

const DiscountFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        invalid,
        intl,
        discountError,
        isDiscountActive,
      } = fieldRenderProps;

      const codeLabel = intl.formatMessage({
        id: 'DiscountForm.codeLabel',
      });
      const codePlaceholder = intl.formatMessage({
        id: 'DiscountForm.codePlaceholder',
      });
      const codeRequiredMessage = intl.formatMessage({
        id: 'DiscountForm.codeRequired',
      });
      const codeRequired = validators.requiredAndNonEmptyString(codeRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitDisabled = invalid || isDiscountActive;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <IconDiscount className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="DiscountForm.heading" />
          </h2>
          <FieldTextInput
            className={css.field}
            type="text"
            name="code"
            id={formId ? `${formId}.code` : 'code'}
            label={codeLabel}
            placeholder={codePlaceholder}
            validate={codeRequired}
            disabled={isDiscountActive}
          />
          <div className={submitButtonWrapperClassName}>
            {discountError ? (
              <p className={css.error}>
                <FormattedMessage id="DiscountForm.codeError" />
              </p>
            ) : null}
            <PrimaryButton type="submit" disabled={submitDisabled}>
              <FormattedMessage id="DiscountForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

DiscountFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
};

DiscountFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const DiscountForm = compose(injectIntl)(DiscountFormComponent);

DiscountForm.displayName = 'DiscountForm';

export default DiscountForm;
