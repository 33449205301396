import React, { useMemo } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingSessionForm } from '../../forms';
import { withRouter } from 'react-router';

import PayOrApplyCouponPage from '../../containers/PayOrApplyCouponPage/PayOrApplyCouponPage';
import css from './EditListingSessionPanel.module.css';

const EditListingSessionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    location,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, title } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingSessionsPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage
              id="EditListingSessionPanel.listingTitle"
              values={{ listingTitle: title }}
            />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingSessionPanel.createListingTitle" />
  );

  const publishable = useMemo(() => {
    if (typeof location?.state?.publishable !== 'undefined') {
      return location?.state?.publishable;
    }
    return currentListing?.attributes?.metadata?.publishable;
  }, [currentListing, location]);

  if (!publishable && publicData?.category === 'mini_session') {
    return (
      <PayOrApplyCouponPage
        currentListing={currentListing}
        currentListingId={currentListing.id.uuid}
      />
    );
  }

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingSessionForm
        className={css.form}
        initialValues={{
          session_length: publicData.session_length,
          session_date: publicData.session_date,
          availabilityOn: publicData?.availabilityOn,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { session_length, session_date, availabilityOn } = values;

          let updateValues;
          if (availabilityOn == 'ownWebsite') {
            updateValues = {
              publicData: {
                availabilityOn,
              },
            };
          } else {
            updateValues = {
              publicData: {
                session_length: Number(session_length),
                session_date,
                availabilityOn,
              },
            };
          }

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        params={params}
      />
    </div>
  );
};

EditListingSessionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingSessionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default withRouter(EditListingSessionPanel);
