import React from 'react';
import { object, string, array } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldMultiSelect.module.css';

const classNamePrefix = params => {
  const { value, valid, hasError } = params;

  if (value && valid) {
    return 'selectSuccess';
  } else if (hasError) {
    return 'selectError';
  }

  return 'select';
};

const FieldMultiSelectComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    options,
    ...rest
  } = props;

  // As react-select doesn't support SSR we need
  // to ensure that the winodw is defined before
  // calling it
  if (typeof window === 'undefined') {
    return null;
  } else if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { default: Select } = require('react-select'); // eslint-disable-line global-require
  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(selectClassName, css.select, {
    [css.selectSuccess]: input.value && valid,
    [css.selectError]: hasError,
  });

  const { ...restOfInput } = input;
  const selectProps = {
    className: selectClasses,
    classNamePrefix: classNamePrefix({
      value: input.value,
      valid,
      hasError,
    }),
    id,
    ...restOfInput,
    ...rest,
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Select options={options} {...selectProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldMultiSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  options: null,
};

FieldMultiSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  options: array.isRequired,
};

const FieldMultiSelect = props => {
  return <Field component={FieldMultiSelectComponent} {...props} />;
};

export default FieldMultiSelect;
