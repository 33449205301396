import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PhotographyContract.module.css';

const PhotographyContract = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: May 5, 2021</p>

      <p>
        This Photography Contract (“Contract”) is entered into between PictureHum and the
        photographer.
      </p>

      <p>
        <strong>SERVICES:</strong> Pursuant to the terms of this contract, Photographer hereby
        agrees to provide the following services to the Client:{' '}
      </p>

      <ul>
        <li>Approximate 60-minute family photography session. </li>
        <li>No less than 50 images taken during photography session.</li>
        <li>Within 5 business days an online photo gallery will be provided to the Client. </li>
        <li>Images will undergo basic editing and be delivered in jpeg format.</li>
        <li>The Client will be able to download all of the images in the gallery. </li>
        <li>
          If the Client finds the Photographer through PictureHum, and initiates a conversation, the
          Photographer agrees not to go outside PictureHum to book the Client.{' '}
        </li>
        <li>I agree to pay PictureHum 8% of the Service Retainer as the Commission Fee.</li>
      </ul>

      <p>
        <strong>SERVICE RETAINER:</strong> Service Retainer is the full session fee. Photography
        session fee will be paid in full when the Client books the session. 92% of the service
        retainer will be paid to the Photographer once the images are delivered to the Client by the
        Photographer. The photography session cannot be held until the Client has submitted the
        service retainer.
      </p>

      <p>
        <strong>SESSION FEE:</strong> For the services described above, the photography session fee
        is determined by the Photographer.
      </p>

      <p>
        <strong>RESCHEDULING and CANCELLATION:</strong> If the Client cancels photo session more
        than 48-hours prior to scheduled session, the full amount of the session will be refunded to
        the Client. If the Client cancels the photo session less than 48-hours prior to scheduled
        session, without rescheduling within 60 days, the full amount of the session will be paid to
        the Photographer.
      </p>

      <p>
        <strong>PHOTOGRAPHS: </strong> Client’s digital gallery will be provided to the Client
        approximately 5 business days from the session date. Photographer is not responsible for
        photographs that do not turn out or photographs that Client believes Photographer “missed”.
        Photographer will attempt to capture the images the Client desires, but Photographer does
        not guarantee Client certain photographs or certain poses.
      </p>

      <p>
        <strong>LIMITATION OF EDITING:</strong> Photo session fee covers basic editing (color,
        exposure, composition).
      </p>

      <p>
        <strong>CREATIVE LICENSE:</strong> Images are edited at the Photographer’s discretion, and
        delivered images and prints may not include all images shot. The Photographer reserves the
        creative right to edit and release only those deemed professional in quality and within the
        Photographer’s artistic standards. RAW and unedited (digital negatives) files will not be
        released to Client. Files that will be released will be in jpeg format.
      </p>

      <p>
        <strong>RIGHT TO OWNERSHIP/REPRODUCTION/PUBLICATION:</strong> Photographer owns the
        copyright to all images. In order for the images created by the Photographer to be used on
        the Photographer’s website, blog, Facebook, Instagram, and in the Photographer’s promotional
        materials, Photographer must specifically request permission from the Client to do so.
        PictureHum may also use these images as samples of their work to show prospective clients.
      </p>

      <p>
        The Client may reproduce the images created for personal, non-commercial use, including the
        reproduction of copies for family and friends. The Client will not permit or assist in any
        commercial use or sale of the images without specific, written consent of the Photographer
        and PictureHum.
      </p>

      <p>
        <strong>LIMITATION OF LIABILITY:</strong> If PictureHum Photographer cannot perform this
        contract due to any cause beyond control of the Photographer, then the Photographer shall
        return Session Fee to the Client, but shall have no further liability with respect to the
        contract. This limitation of liability shall also apply in the event that the photographic
        materials are damaged in processing, lost through camera malfunction, lost in the mail, or
        otherwise damaged without fault on the part of PictureHum Photographer. In the event that
        the Photographer fails to perform for any other reason, liability will not be for any amount
        in excess of the service retainer.
      </p>

      <p>
        <strong>PHOTOGRAPHER’S RELEASE OF LIABILITY:</strong> In consideration of participating in a
        photo session, I, for myself, my spouse, my child(ren), my heirs, personal representative,
        next of kin, and assigns, voluntarily agree to release, waive, discharge, and covenant not
        to sue PictureHum (collectively “Release Parties”) and each of their officers, agents,
        employees, and volunteers from any and all liabilities for any accident, illness, death,
        wrongful death, or property damage/loss arising out of my participation in the photo
        session, whether occurring on or off the Released Parties’ property, and whether such
        accident, illness, injury, death, wrongful death, or property damage/loss is caused by the
        negligence of the Release Parties (excepting gross negligence) or otherwise. Photographer
        acknowledges that participation is voluntary and that each person participating in the photo
        shoot is doing so voluntarily. Photographer knowingly assumes any and all risks, known and
        unknown.
      </p>

      <p>
        <strong>INDEMNITY AGREEMENT:</strong> In consideration of participating in the photo
        session, I voluntarily agree to indemnify and hold harmless the Released Parties from any
        and all claims, demands, liabilities, causes of action, costs and expenses (including
        attorneys’ fees) brought as a result of my participation in the photo session and/or use of
        the Released Parties’ equipment, whether caused by the negligence of the Released Parties
        (excepting gross negligence) or otherwise.
      </p>

      <p>
        <strong>SEVERABILITY:</strong> I understand and acknowledge that this Agreement is intended
        to be as broad and inclusive as permitted by law. If any portion of this Agreement is deemed
        invalid, it is agreed that the remaining portion if the Agreement shall continue in full
        legal force and effect.
      </p>

      <p>
        <strong>COMPLETENESS OF THIS AGREEMENT:</strong> This Agreement sets for the entire
        understanding of the parties relating to the subject matter, and supersedes all prior
        agreements, arrangements and understandings, written or oral, relating to such subject
        matter. No representation, promise or inducement has been made by either party that is not
        embodied in this Agreement, and neither party shall be bound by or liable for any alleged
        representation, promise or inducement not included in this agreement.
      </p>

      <p>
        <strong>CONFIDENTIALITY:</strong> The parties agree that the terms of this Agreement are
        confidential and neither party may disclose the terms of this Agreement to any third party
        without the non-disclosing party’s prior written consent. Neither party shall be liable to
        the other for any punitive special, indirect or consequential damages, including but not
        limited to damages for lost profits or loss of business goodwill, whether or not a party has
        reason to know of the potential for such damages.
      </p>

      <p><strong>APPLICABLE LAW:</strong> The laws of the State of California shall govern this agreement.</p>
    </div>
  );
};

PhotographyContract.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PhotographyContract.propTypes = {
  rootClassName: string,
  className: string,
};

export default PhotographyContract;
