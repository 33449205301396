import React, { useState } from 'react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import ItemsCarousel from 'react-items-carousel';
import { IconArrowHead } from '../../components';

import css from './Carousel.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

// This can be overwritten with
// main component props
const GUTTER = 0;
const ACTIVE_POSITION = 'center';
const CHEVRON_WIDTH = 60;
const SLIDES_TO_SCROLL = 1;

const Chevron = props => {
  // Default position is right and in case
  // you need the chevron in left position
  // you can just call opposite from props
  const { opposite } = props;
  const classes = classNames(css.chevron, {
    [css.chevronLeft]: opposite,
  });
  return (
    <span className={classes}>
      <IconArrowHead className={css.chevronIcon} direction="right" />
    </span>
  );
};

const CarouselComponent = props => {
  const {
    rootClassName,
    className,
    viewport,
    children,
    showCarousel,
    landingPage,
    infiniteLoop,
  } = props;

  // Set and track active item state
  // with hooks
  const [active, setActive] = useState(0);

  // Check if the current viewport is
  // coming from mobile layout
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  // Set number of cards to show in both
  // mobile and desktop layout
  const numberOfCards = isMobileLayout
    ? props.numberOfCards?.mobile || 1
    : props.numberOfCards?.desktop || 1;

  const gutter = props.gutter || GUTTER;
  const slidesToScroll = isMobileLayout
    ? props.slidesToScroll?.mobile || 1
    : props.slidesToScroll?.desktop || 1;

  // As carousel used in LandingPage and ListingPage
  // has completely different arrows we need to declare
  // the unique class that will be present on both pages
  const classes = classNames(rootClassName || css.root, className, {
    [css.landingPageRoot]: landingPage,
    [css.listingPageRoot]: !landingPage,
  });

  if (showCarousel && !children) {
    throw new Error('You need to pass children as props.');
  }

  return showCarousel ? (
    <ItemsCarousel
      classes={{
        wrapper: classes,
        rightChevronWrapper: css.chevronRightContainer,
        leftChevronWrapper: css.chevronLeftContainer,
      }}
      gutter={gutter}
      activePosition={ACTIVE_POSITION}
      chevronWidth={CHEVRON_WIDTH}
      numberOfCards={numberOfCards}
      slidesToScroll={slidesToScroll}
      activeItemIndex={active}
      infiniteLoop={infiniteLoop ? infiniteLoop : false}
      disableSwipe={false}
      alwaysShowChevrons={false}
      outsideChevron={false}
      showSlither={false}
      firstAndLastGutter={false}
      requestToChangeActive={value => setActive(value)}
      rightChevron={<Chevron />}
      leftChevron={<Chevron opposite />}
    >
      {children}
    </ItemsCarousel>
  ) : null;
};

CarouselComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, shape, number } = PropTypes;

CarouselComponent.propTypes = {
  rootClassName: string,
  className: string,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const Carousel = compose(withViewport)(CarouselComponent);

export default Carousel;
