import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import {
  maxLength,
  required,
  composeValidators,
  uniqueListingSlug,
  validListingSlug,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldCheckboxGroup } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import css from './EditListingDescriptionForm.module.css';
import { createSlug } from '../../util/urlHelpers';

const TITLE_MAX_LENGTH = 60;
const photographerServiceName = 'photographerServices';

const EditListingDescriptionFormComponent = props => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          subCategories,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          params,
          publicData,
          photographerServices,
          categoryType,
          listingSlugs,
          isPublished,
        } = formRenderProps;

        const category = publicData?.category ? publicData.category : params.category;
        const isFamilyPhotographySelected = values => {
          if (category === categoryType) {
            return true;
          } else {
            const result = values?.photographerServices?.includes('family_photography')
              ? true
              : false;
            return result;
          }
        };

        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });

        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const titleRequired = required(titleRequiredMessage);

        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );
        const serviceMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.serviceLabelMessage',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });
        const photographerMessage = intl.formatMessage({ id: 'EditListingProfileForm.title' });
        const photographerPlaceholderMessage = intl.formatMessage({
          id: 'EditListingProfileForm.titlePlaceholder',
        });
        const photographerRequiredMessage = intl.formatMessage({
          id: 'EditListingProfileForm.titleRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || !isFamilyPhotographySelected(values);

        const region = values.subCategory;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(titleRequired, maxLength60Message)}
            />

            <FieldTextInput
              id="slug"
              name="slug"
              className={css.title}
              type="text"
              label="Slug"
              placeholder="your-listing-slug"
              validate={composeValidators(
                required('Slug is required'),
                uniqueListingSlug('Slug must be unique', listingSlugs),
                validListingSlug(
                  "Slug is invalid. Slug can't contain commas, spaces, uppercase letters or any special characters."
                )
              )}
              innerText={
                region
                  ? `${process.env.REACT_APP_CANONICAL_ROOT_URL}/photographer/${region.replaceAll(
                      '_',
                      '-'
                    )}/`
                  : `${process.env.REACT_APP_CANONICAL_ROOT_URL}/photographer/`
              }
              infoTooltipMessage={
                isPublished
                  ? null
                  : 'Your unique listing link is only available after your listing is published.'
              }
              infoText="To increase the visibility of your listing and improve seo, please choose a description specific to your photography."
              copyToClipboard
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={required(descriptionRequiredMessage)}
            />

            {category !== categoryType ? (
              <CustomCategorySelectFieldMaybe
                id="subCategory"
                name="subCategory"
                categories={subCategories}
                intl={intl}
              />
            ) : null}

            {category === categoryType ? (
              <FieldTextInput
                id="photographer_name"
                name="photographer_name"
                type="text"
                label={photographerMessage}
                placeholder={photographerPlaceholderMessage}
                validate={required(photographerRequiredMessage)}
              />
            ) : (
              <FieldCheckboxGroup
                label={serviceMessage}
                id={photographerServiceName}
                name={photographerServiceName}
                options={photographerServices}
              />
            )}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
