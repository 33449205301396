import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, IconReviewStar } from '../../components';

import IconCamera from './IconCamera';
import css from './ReviewsCard.module.css';

// Set the default review state to
// public
const REVIEW_STATE = 'public';

const ReviewsCard = props => {
  const { rootClassName, className, review } = props;

  if (!review) {
    throw new Error("Review doesn't exist on the marketplace.");
  }

  // Destruct the review
  const { state, content, rating } = review?.attributes;

  // The listing that the review is about
  const { images } = review?.listing;
  const { title: listingTitle } = review?.listing?.attributes;

  // The user that wrote the review
  const { displayName: authorDisplayName } = review?.author?.attributes?.profile;

  // The user that the review is about
  const { displayName: subjectDisplayName } = review?.subject?.attributes?.profile;

  // Check wheter review has been published
  const isReviewPublished = state === REVIEW_STATE;

  // As we are always showing 5 stars and then filling them
  // depending on the rating, we need to create an 5 keys array
  const newStarReviewArray = [...Array(5).keys()];

  // Listing image
  const listingImage = images && images[0];

  const classes = classNames(rootClassName || css.root, className);

  return isReviewPublished ? (
    <div className={classes}>
      <div className={css.aspectWrapper}>
        <ResponsiveImage
          rootClassName={css.image}
          alt={listingTitle}
          image={listingImage}
          variants={['landscape-crop']}
        />
      </div>
      <div className={css.info}>
        <p className={css.text}>{content}</p>
        <div className={css.heading}>
          <div className={css.stars}>
            {newStarReviewArray.map(star => {
              return <IconReviewStar key={star} className={css.star} isFilled={star < rating} />;
            })}
          </div>
          <span>{authorDisplayName}</span>
        </div>
        <p className={css.shootedBy}>
          <IconCamera className={css.cameraIcon} />
          <FormattedMessage id="ReviewsCard.subjectDisplayName" values={{ subjectDisplayName }} />
        </p>
      </div>
    </div>
  ) : null;
};

ReviewsCard.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ReviewsCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReviewsCard;
