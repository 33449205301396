import React from 'react';
import Slider from 'react-slick';

import css from './SectionHero.module.css';

// Slider images
import firstImage from './images/hero_first.jpg';
import secondImage from './images/hero_second.jpg';
import thirdImage from './images/hero_third.jpg';
import fourthImage from './images/hero_fourth.jpg';
import fifthImage from './images/hero_fifth.jpg';
import sixthImage from './images/hero_sixth.jpg';
import seventhImage from './images/hero_seventh.jpg';
import eightImage from './images/hero_eigth.jpg';

// React-slick setup
const carouselSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  infinite: true,
  fade: true,
  pauseOnHover: false,

  // Enable autoplay
  autoplay: true,
  autoplaySpeed: 4000,

  // Disable arrows and dots
  dots: false,
  arrows: false,
  lazyLoad: 'ondemand',
};

const carouselImage = (image, photographer) => {
  const gradient = 'linear-gradient(-45deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5))';
  return (
    <div className={css.carouselItemContainer}>
      <div
        className={css.carouselItem}
        style={{
          backgroundImage: `${gradient}, url(${image})`,
        }}
      />
      <div className={css.photographer}>{photographer}</div>
    </div>
  );
};

const HeroCarousel = props => {
  const { intl } = props;

  const photographerMessage = (name, country) => {
    return intl.formatMessage(
      {
        id: 'SectionHero.photographerMessage',
      },
      {
        name: (
          <span key={name} className={css.photographerName}>
            {name}
          </span>
        ),
        country,
      }
    );
  };
  return (
    <Slider {...carouselSettings}>
      {carouselImage(firstImage, photographerMessage('Irina', 'San Diego'))}
      {carouselImage(secondImage, photographerMessage('Kristen', 'the Bay Area'))}
      {carouselImage(thirdImage, photographerMessage('Abi', 'the Bay Area'))}
      {carouselImage(fourthImage, photographerMessage('Juliet', 'the Bay Area'))}
      {carouselImage(fifthImage, photographerMessage('Irina', 'San Diego'))}
      {carouselImage(sixthImage, photographerMessage('Andrianna', 'Washington D.C.'))}
      {carouselImage(seventhImage, photographerMessage('Lisa', 'the Bay Area'))}
      {carouselImage(eightImage, photographerMessage('Brittney', 'San Diego'))}
    </Slider>
  );
};

export default HeroCarousel;
