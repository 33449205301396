import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import {
  required,
  composeValidators,
  checkValidWebsite,
  bookingDateRequired,
} from '../../util/validators';
import { Form, FieldTextInput, FieldSelect, FieldDateInput } from '../../components';
import config from '../../config';
import moment from 'moment';

import css from './EditListingSessionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingSessionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
        intl,
        form,
        fetchErrors,
        values,
        handleNativeChange,
        handleOnChange,
        relevantTimezones,
        sessionDate,
        sessionLength,
        availabilityOn,
        isPublished,
        turnaroundTimeConfig,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingSessionForm.title' });

      const sessionDateMessage = intl.formatMessage({
        id: 'EditListingSessionForm.sessionDateMessage',
      });

      const formatDateForPlaceholder = sessionDate
        ? moment(new Date(sessionDate)).format('ddd, MMM DD')
        : null;
      const sessionDatePlaceholderMessage = sessionDate
        ? intl.formatMessage(
            {
              id: 'EditListingSessionForm.sessionDatePlaceholderWithDate',
            },
            { date: formatDateForPlaceholder }
          )
        : intl.formatMessage({
            id: 'EditListingSessionForm.sessionDatePlaceholder',
          });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);

      const websiteMessage = intl.formatMessage({ id: 'EditListingAvailabilityPanel.website' });
      const websitePlaceholderMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.websitePlaceholder',
      });
      const websiteRequiredMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.websiteRequired',
      });

      const digitalFilesMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.digitalFiles',
      });
      const digitalFilesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.digitalFilesPlaceholder',
      });
      const digitalFilesRequiredMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.digitalFilesRequired',
      });

      const invalidWebsiteMessage = intl.formatMessage({
        id: 'EditListingAvailabilityPanel.websiteInvalidMessage',
      });

      return (
        <Form
          className={classes}
          onSubmit={e => {
            handleSubmit(e).then(() => {
              form.initialize({
                session_length: null,
                session_date: null,
              });
            });
          }}
        >
          <FormSpy
            subscription={{ values: true }}
            onChange={values => {
              handleOnChange(values);
            }}
          />
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldSelect
            name="availabilityOn"
            id="availabilityOn"
            label="Tell us where you want your users to book you"
            className={css.title}
            nativeChange={handleNativeChange}
            disabled={availabilityOn}
          >
            <option disabled value="">
              Please select an option
            </option>
            {config.custom.miniSessionBookingOptions.map(b => {
              return (
                <option key={b.key} value={b.key}>
                  {b.value}
                </option>
              );
            })}
          </FieldSelect>

          {values?.availabilityOn === 'currentWebsite' ? (
            <>
              <FieldSelect
                label={titleMessage}
                id="session_length"
                name="session_length"
                className={css.title}
                disabled={sessionLength}
              >
                <option value="" selected hidden>
                  Select option...
                </option>
                {config.custom.sessionLengthOptions.map(s => (
                  <option key={s.key} value={s.key}>
                    {s.label}
                  </option>
                ))}
              </FieldSelect>

              <FieldDateInput
                className={css.title}
                name="session_date"
                id="session_date"
                label={sessionDateMessage}
                placeholderText={sessionDatePlaceholderMessage}
                onChange={value => {
                  return value;
                }}
                useMobileMargins
                showErrorMessage={false}
                validate={bookingDateRequired('Required')}
                disabled={!!sessionDate}
              />

              {/* <FieldSelect
                id="timezone"
                name="timezone"
                label="Timezone"
                className={css.title}
                disabled={isPublished}
              >
                <option value="" selected hidden>
                  Select Timezone...
                </option>
                {relevantTimezones?.map(tz => (
                  <option value={tz} key={tz}>
                    {tz}
                  </option>
                ))}
              </FieldSelect> */}

              <FieldTextInput
                className={css.title}
                name="digitalFiles"
                id="digitalFiles"
                type="text"
                label={digitalFilesMessage}
                placeholder={digitalFilesPlaceholderMessage}
                validate={required(digitalFilesRequiredMessage)}
                readOnly={isPublished}
              />

              <FieldSelect
                id="turnaroundTime"
                name="turnaroundTime"
                label="What is the turnaround time?"
                className={css.title}
                disabled={isPublished}
              >
                <option value="" selected hidden>
                  Select the turnaround time
                </option>
                {turnaroundTimeConfig?.map(time => (
                  <option value={time.key} key={time.key}>
                    {time.label}
                  </option>
                ))}
              </FieldSelect>
            </>
          ) : values?.availabilityOn === 'ownWebsite' ? (
            <FieldTextInput
              id="website"
              name="website"
              className={css.website}
              type="text"
              label={websiteMessage}
              placeholder={websitePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(
                required(websiteRequiredMessage),
                checkValidWebsite(invalidWebsiteMessage)
              )}
              disabled={isPublished}
            />
          ) : null}
        </Form>
      );
    }}
  />
);

EditListingSessionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingSessionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingSessionFormComponent);
