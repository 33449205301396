import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { NewsletterForm } from '../../forms';

import css from './SectionNewsletter.module.css';

const MAILCHIMP_API_URL = process.env.REACT_APP_MAILCHIMP_API;

const validateStatus = (currStatus, statusMessage) => {
  return currStatus === statusMessage;
};

const SectionNewsletter = () => {
  return (
    <MailchimpSubscribe
      url={MAILCHIMP_API_URL}
      render={({ subscribe, status, message }) => {
        // Declare status messages
        const pendingStatus = validateStatus(status, 'sending');
        const successStatus = validateStatus(status, 'success');
        const errorStatus = validateStatus(status, 'error');

        const handleSubmit = values => {
          const { email } = values;

          const formData = {
            EMAIL: email,
          };

          subscribe(formData);
        };
        return (
          <div className={css.root}>
            <NewsletterForm
              onSubmit={handleSubmit}
              newsletterMessage={message}
              newsletterSuccess={successStatus}
              newsletterError={errorStatus}
              inProgress={pendingStatus}
            />
          </div>
        );
      }}
    />
  );
};

export default SectionNewsletter;
