import React, { Component } from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { parse } from '../../util/urlHelpers';
import { FilterPopup, FilterPlain } from '../../components';
import { SearchLocationForm } from '../../forms';
import config from '../../config';

import css from './SearchLocationFilter.module.css';

const getKeywordQueryParam = queryParamNames => {
  return Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : 'subCategory';
};

class SearchLocationFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;
    this.mobileInputRef = React.createRef();

    this.positionStyleForContent = this.positionStyleForContent.bind(this);

    this.state = {
      submitted: false,
      selectedAddress: null,
    };
  }

  componentWillUnmount() {
    window.clearTimeout(this.shortKeywordTimeout);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      intl,
      showAsPopup,
      history,
      options,
      cancelFilters,
      filterConfig,
      location,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const urlParam = getKeywordQueryParam(queryParamNames);

    const { address, origin, bounds } = parse(location?.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: initialValues[urlParam] };

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const handleSubmit = selectedAddress => {
      const { currentQueryParams: currentSearchParams, history } = this.props;
      const { search, selectedPlace } =
        selectedAddress?.location || this.state.selectedAddress.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const searchParams = {
        ...currentSearchParams,
        ...originMaybe,
        address: search,
        bounds,
      };

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    };

    const handleClear = () => {
      const { currentQueryParams: currentSearchParams, history } = this.props;

      const searchParams = {
        ...currentSearchParams,
      };

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    };

    const labelText = initialValues[urlParam];
    const labelForPopup = !!labelText
      ? intl.formatMessage({ id: 'SearchLocationFilter.labelSelected' }, { labelText })
      : label;

    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={!!initialValues[urlParam]}
        id={`${id}.popup`}
        showAsPopup
        labelMaxWidth={250}
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        onClear={handleClear}
        isSearchLocationFilter={true}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <SearchLocationForm
          onSubmit={values => this.setState({ selectedAddress: values })}
          initialValues={initialSearchFormValues}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        isSelected={!!initialValues[urlParam]}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        onClear={handleClear}
        isSearchLocationFilter={true}
        initialValues={namedInitialValues}
        {...rest}
      >
        <SearchLocationForm
          onSubmit={values => handleSubmit(values)}
          initialValues={initialSearchFormValues}
        />
      </FilterPlain>
    );
  }
}

SearchLocationFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
  filterConfig: config.custom.filters,
};

SearchLocationFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: shape({
    keyword: string,
  }),
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchLocationFilter);
