import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import { findOptionsForSelectFilter } from '../../util/search';
import { _ } from 'core-js';
import { isEmpty } from 'lodash';
import config from '../../config';
import moment from 'moment';

import css from './EditListingDescriptionPanel.module.css';

const fixedService = 'family_photography';
const categoryType = 'mini_session';

function generateRandomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
    listingSlugs,
  } = props;

  const [initialProps, setInitialProps] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing}>{title}</ListingLink>,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  const category = publicData.category || params.category;
  let initialValues = null;
  if (category === 'photographer') {
    let photographerServicesInitialValue = null;
    const condition = isEmpty(publicData) ? true : false;
    if (condition) {
      photographerServicesInitialValue = [fixedService];
    } else {
      photographerServicesInitialValue = [...publicData?.photographerServices]?.includes(
        fixedService
      )
        ? [...publicData?.photographerServices]
        : [...publicData?.photographerServices, fixedService];
    }
    initialValues = {
      title,
      description,
      subCategory: publicData?.subCategory,
      photographerServices: photographerServicesInitialValue,
      slug: publicData?.slug,
    };
  } else {
    initialValues = {
      title,
      description,
      photographer_name: publicData?.photographer_name,
      subCategory: publicData?.subCategory,
      city: publicData?.city,
      slug: publicData?.slug,
    };
  }

  const photographerServices = findOptionsForSelectFilter(
    'photographerServices',
    config.custom.filters
  );
  const subCategories = findOptionsForSelectFilter('subCategory', config.custom.filters);
  const citiesConfig = findOptionsForSelectFilter('cities', config.custom.filters);

  if (updateInProgress) {
    initialValues = { ...initialValues, ...initialProps };
  }
  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            subCategory: cityCategory,
            city,
            photographerServices = [],
            photographer_name,
            slug,
          } = values;

          const isMiniSession = category === categoryType;
          const subCategory = isMiniSession ? null : cityCategory;

          const photographerServicesArray =
            category !== categoryType
              ? {
                  photographerServices,
                }
              : {};

          const randomDate = generateRandomDate(new Date(2012, 0, 1), new Date());
          const timestamp = publicData?.timestamp;
          const timestampMaybe = timestamp ? {} : { timestamp: moment(randomDate).valueOf() };

          const updateValues = {
            title: title.trim(),
            description,
            publicData: {
              category: publicData.category ?? category,
              subCategory,
              city,
              photographer_name,
              slug,
              ...photographerServicesArray,
              ...timestampMaybe,
            },
          };
          setInitialProps({ title, description, ...updateValues.publicData });
          onSubmit(updateValues);
        }}
        publicData={publicData}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        subCategories={subCategories}
        params={params}
        photographerServices={photographerServices}
        categoryType={categoryType}
        citiesConfig={citiesConfig}
        listingSlugs={listingSlugs}
        isPublished={isPublished}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
