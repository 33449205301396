import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import css from './IconSubmit.module.css';

const IconSubmit = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM14 6.6261L14.3536 6.97965L19.0202 11.6463L19.3738 11.9999L19.0202 12.3534L14.3536 17.0201L14 17.3736L13.2929 16.6665L13.6464 16.313L17.4595 12.5H5.33325V11.5H17.4596L13.6464 7.68676L13.2929 7.33321L14 6.6261Z"
        fill="#DB5F43"
      />
    </svg>
  );
};

IconSubmit.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconSubmit.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconSubmit;
