import React, { useState } from 'react';
import { arrayOf, bool, func, object, string, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, getTimeZoneNames, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  Button,
  IconClose,
  IconSpinner,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
import { EditListingAvailabilityExceptionForm, EditListingSessionForm } from '../../forms';
import config from '../../config';
import moment from 'moment';

import css from './EditListingAvailabilityPanel.module.css';

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

const matchTimezone = (tz, timezone) => tz === timezone;

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
    turnaroundTimeConfig,
  } = props;

  // Hooks
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [availabilityOn, setAvailabilityOn] = useState('');
  const [sessionDate, setSessionDate] = useState('');
  const [completed, setCompleted] = useState(false);

  const [payload, setPayload] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const { title } = currentListing?.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  const publicData = currentListing?.attributes?.publicData;

  React.useEffect(() => {
    if (publicData?.availabilityOn != availabilityOn) {
      setAvailabilityOn(publicData?.availabilityOn);
    }
  }, [currentListing?.attributes?.publicData]);
  React.useEffect(() => {
    if (
      typeof publicData?.session_date !== 'undefined' ||
      typeof publicData?.date !== 'undefined'
    ) {
      const formattedDate = moment(new Date(publicData?.date)).format('YYYY-MM-DD');
      setSessionDate(publicData?.session_date || formattedDate);
    }
  }, [currentListing?.attributes?.publicData]);
  React.useEffect(() => {
    const sessDate = publicData?.session_date;
    const sessLength = publicData?.session_length;
    const sessAvailability = publicData?.availabilityOn;

    if ((sessDate, sessLength, sessAvailability)) {
      setCompleted(true);
    }
  }, [publicData]);

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);
  let submitButtonValidation;
  if (payload) {
    if (payload?.publicData?.availabilityOn) {
      const pub_data = payload?.publicData;
      const availability_plan = payload?.availabilityPlan;
      switch (pub_data?.availabilityOn) {
        case 'ownWebsite':
          submitButtonValidation = !!(pub_data?.website && pub_data.availabilityOn);
          break;
        case 'currentWebsite':
          submitButtonValidation = !!(
            pub_data?.availabilityOn &&
            pub_data.session_length &&
            exceptionCount != 0
          );
          break;
      }
    }
  }

  // Save exception click handler
  const saveException = values => {
    const { exceptionStartTime, exceptionEndTime } = values;

    const availability = 'available';
    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const handleExceptionSubmit = () => {
    onSubmit(payload).then(res => onNextTab());
  };

  // IANA database contains irrelevant time zones too.
  const relevantZonesPattern = new RegExp(
    '^(Africa|America(?!/(Argentina/ComodRivadavia|Knox_IN|Nuuk))|Antarctica(?!/(DumontDUrville|McMurdo))|Asia(?!/Qostanay)|Atlantic|Australia(?!/(ACT|LHI|NSW))|Europe|Indian|Pacific)'
  );

  const relevantTimezones = getTimeZoneNames(relevantZonesPattern).filter(
    tz =>
      matchTimezone(tz, 'America/New_York') ||
      matchTimezone(tz, 'America/Chicago') ||
      matchTimezone(tz, 'America/Denver') ||
      matchTimezone(tz, 'America/Los_Angeles')
  );

  const handleOwnWebsitePayload = (availabilityOn, website) => {
    setPayload({
      availabilityPlan: null,
      publicData: {
        availabilityOn,
        website,
        session_length: null,
      },
    });
  };

  const handleCurrentWebsitePayload = (
    session_date,
    session_length,

    availabilityOn,
    digitalFiles,
    turnaroundTime
  ) => {
    setPayload({
      availabilityPlan: {
        type: 'availability-plan/time',
        timezone: defaultTimeZone(),
        entries: [],
      },
      publicData: session_date
        ? {
            availabilityOn,
            session_length: Number(session_length),
            session_date: session_date ? moment(session_date?.date).format('YYYY-MM-DD') : null,
            date: session_date
              ? Date.parse(new Date(moment(session_date?.date).format('YYYY-MM-DD')))
              : null,
            digitalFiles,
            turnaroundTime,
            website: null,
          }
        : {
            availabilityOn,
            session_length: Number(session_length),
            website: null,
          },
    });

    if (session_length && availabilityOn && session_date) {
      setCompleted(true);
    }
  };

  const handleOnChange = formValues => {
    const { values } = formValues;
    const {
      session_length,
      website,
      availabilityOn,

      session_date,
      digitalFiles,
      turnaroundTime,
    } = values;
    if (availabilityOn === 'ownWebsite') {
      handleOwnWebsitePayload(availabilityOn, website);
    } else if (availabilityOn === 'currentWebsite') {
      handleCurrentWebsitePayload(
        session_date,
        session_length,

        availabilityOn,
        digitalFiles,
        turnaroundTime
      );
    } else {
    }
  };

  return (
    <main className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{
              listingTitle: (
                <ListingLink listing={listing}>
                  <FormattedMessage
                    id="EditListingAvailabilityPanel.listingTitle"
                    values={{ listingTitle: title }}
                  />
                </ListingLink>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      <EditListingSessionForm
        className={css.form}
        initialValues={{
          session_length: publicData.session_length,
          // session_date: publicData?.session_date
          //   ? { date: new Date(publicData?.session_date) }
          //   : null,
          availabilityOn: publicData?.availabilityOn,

          digitalFiles: publicData?.digitalFiles,
          turnaroundTime: publicData?.turnaroundTime,
          website: publicData?.website,
        }}
        relevantTimezones={relevantTimezones}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          return values;
        }}
        handleOnChange={handleOnChange}
        onChange={values => {
          return values;
        }}
        disabled={disabled}
        ready={ready}
        updated={false && panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        params={{} || params}
        handleNativeChange={value => (value == 'ownWebsite' ? setAvailabilityOn(value) : null)}
        sessionDate={sessionDate}
        sessionLength={publicData?.session_length}
        availabilityOn={publicData?.availabilityOn}
        digitalFiles={publicData?.digitalFiles}
        isPublished={isPublished}
        turnaroundTimeConfig={turnaroundTimeConfig}
      />

      {payload?.publicData.availabilityOn == 'currentWebsite' ? (
        <>
          <section className={css.section}>
            <header className={css.sectionHeader}>
              <h2 className={css.sectionTitle}>
                {fetchExceptionsInProgress ? (
                  <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
                ) : (
                  <FormattedMessage
                    id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
                    values={{
                      count: exceptionCount,
                    }}
                  />
                )}
              </h2>
            </header>
            {fetchExceptionsInProgress ? (
              <div className={css.exceptionsLoading}>
                <IconSpinner />
              </div>
            ) : exceptionCount === 0 ? (
              <div className={css.noExceptions}>
                <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
              </div>
            ) : (
              <div className={css.exceptions}>
                {sortedAvailabilityExceptions.map(availabilityException => {
                  const { start, end, seats } = availabilityException.attributes;

                  return (
                    <div key={availabilityException.id.uuid} className={css.exception}>
                      <div className={css.exceptionHeader}>
                        <div className={css.exceptionAvailability}>
                          <div
                            className={classNames(css.exceptionAvailabilityDot, {
                              [css.isAvailable]: seats > 0,
                            })}
                          />
                          <div className={css.exceptionAvailabilityStatus}>
                            {seats > 0 ? (
                              <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                            ) : (
                              <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
                            )}
                          </div>
                        </div>
                        <button
                          className={css.removeExceptionButton}
                          onClick={() =>
                            onDeleteAvailabilityException({
                              id: availabilityException.id,
                            })
                          }
                        >
                          <IconClose size="normal" className={css.removeIcon} />
                        </button>
                      </div>
                      <TimeRange
                        className={css.timeRange}
                        startDate={start}
                        endDate={end}
                        dateType={DATE_TYPE_DATETIME}
                        timeZone={availabilityPlan.timezone}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </section>
        </>
      ) : null}
      {exceptionCount <= MAX_EXCEPTIONS_COUNT &&
      payload?.publicData?.availabilityOn == 'currentWebsite' ? (
        <InlineTextButton
          className={css.addExceptionButton}
          onClick={() => setIsEditExceptionsModalOpen(true)}
          disabled={disabled || !completed}
          ready={ready}
        >
          <FormattedMessage id="EditListingAvailabilityPanel.addException" />
        </InlineTextButton>
      ) : null}

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      {!isPublished ? (
        <Button
          className={css.goToNextTabButton}
          onClick={handleExceptionSubmit}
          disabled={!submitButtonValidation}
          inProgress={updateInProgress}
        >
          {submitButtonText}
        </Button>
      ) : null}

      {onManageDisableScrolling ? (
        <Modal
          id="EditAvailabilityExceptions"
          isOpen={isEditExceptionsModalOpen}
          onClose={() => setIsEditExceptionsModalOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          usePortal
        >
          <EditListingAvailabilityExceptionForm
            formId="EditListingAvailabilityExceptionForm"
            onSubmit={saveException}
            timeZone={availabilityPlan.timezone}
            payload={payload}
            availabilityExceptions={sortedAvailabilityExceptions}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            sessionLength={payload?.publicData?.session_length}
            sessionDate={
              payload?.publicData?.session_date
                ? payload?.publicData?.session_date
                : sessionDate
                ? sessionDate
                : undefined
            }
          />
        </Modal>
      ) : null}
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
  turnaroundTimeConfig: config.custom.turnaroundTime,
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
