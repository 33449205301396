import React, { useState } from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, AvatarYelp, ReviewRating, UserDisplayName, Modal } from '../../components';
import { propTypes } from '../../util/types';

import css from './Reviews.module.css';
import { MongooseAvatar } from '../Avatar/Avatar';

const YELP_REVIEW = 'yelp';
const BLACKLISTED_REVIEW_IDS = ['xQgyYsaRPIIx8rJ_fujzyw'];

const Review = props => {
  const {
    review,
    oldReview,
    fetchYelpReviewsInProgress,
    customerReview,
    intl,
    setImageCarouselOpen,
    setReviewImage,
  } = props;

  // Declare if the review is coming from Yelp
  const isYelpReview = review?.generatedAt === YELP_REVIEW;

  const reviewImage = oldReview?.image || customerReview?.review_photo;

  return !fetchYelpReviewsInProgress ? (
    <div className={css.review}>
      {isYelpReview ? (
        <AvatarYelp className={css.avatar} user={review.author} />
      ) : review.author ? (
        <Avatar className={css.avatar} user={review.author} disableProfileLink />
      ) : (
        <MongooseAvatar className={css.avatar} review={review} />
      )}
      <div>
        <div className={css.reviewDisplayNameContainer}>
          {review.author ? (
            <UserDisplayName
              rootClassName={css.reviewDisplayName}
              user={review.author}
              intl={intl}
              showFirstName
            />
          ) : (
            <UserDisplayName
              rootClassName={css.reviewDisplayName}
              user={review.author}
              intl={intl}
              mongoosReviewAuthorName={review.customer_name}
              showFirstName
            />
          )}
        </div>

        <span className={css.desktopReviewRatingWrapper}>
          <ReviewRating
            rating={review.author ? review.attributes.rating : review.review_rating}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
        </span>
        <p className={css.reviewContent}>{review?.attributes?.content ?? review.review_message}</p>
        {reviewImage ? (
          <div
            className={css.reviewImageContainer}
            onClick={() => {
              if (reviewImage) {
                setImageCarouselOpen();
                setReviewImage(reviewImage);
              }
            }}
          >
            <img className={css.reviewImage} src={reviewImage} />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const {
    className,
    rootClassName,
    reviews,
    oldReviews,
    fetchYelpReviewsInProgress,
    customerReviews,
    intl,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [reviewImage, setReviewImage] = useState(null);

  return (
    <>
      <ul className={classes}>
        {reviews.map(r => {
          if (BLACKLISTED_REVIEW_IDS.includes(r.id.uuid)) {
            return null;
          }

          const matchCustomerReview = customerReviews?.find(
            c => c.review_message === r.attributes.content
          );
          const oldReview = oldReviews?.find(a => a.id === r.id.uuid);

          return (
            <li key={`Review_${r?.id?.uuid || r?.transaction_id} `} className={css.reviewItem}>
              <Review
                review={r}
                oldReview={oldReview}
                intl={intl}
                fetchYelpReviewsInProgress={fetchYelpReviewsInProgress}
                customerReview={matchCustomerReview}
                setImageCarouselOpen={() => setImageCarouselOpen(true)}
                setReviewImage={image => setReviewImage(image)}
              />
            </li>
          );
        })}
      </ul>
      {reviewImage ? (
        <Modal
          id="Reviews.imageCarousel"
          scrollLayerClassName={css.carouselModalScrollLayer}
          containerClassName={css.carouselModalContainer}
          lightCloseButton
          isOpen={imageCarouselOpen}
          onClose={() => {
            setImageCarouselOpen(false);
            setReviewImage(null);
          }}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.imageRoot}>
            <div className={css.imageRootWrapper}>
              {reviewImage ? <img className={css.image} src={reviewImage} /> : null}
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
