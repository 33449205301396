import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, NamedLink, Button } from '..';

import StripeBox from './StripeBox';
import StripeIcon from './StripeIcon';
import css from './EditListingPayoutDetailsPanel.module.css';

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

const EditListingPayoutDetailsPanel = props => {
  const {
    intl,
    className,
    rootClassName,
    listing,
    currentUserLoaded,
    currentUser,
    stripeConnectForm,
    stripeAccount,
    updateInProgress,
    onSubmit,
    submitButtonText,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPayoutDetailsPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing}>{listing.attributes.title}</ListingLink>,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPayoutDetailsPanel.createListingTitle" />
  );

  const stripeConnected =
    currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;
  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const isStripeConnected = stripeConnected && !requirementsMissing;

  const payoutButtonInProgress = updateInProgress;
  const payoutButtonDisabled = updateInProgress || !currentUserLoaded;
  return (
    <div className={classes}>
      <h1 className={css.title}>
        {panelTitle}
        <StripeIcon className={css.stripeIcon} />
      </h1>
      {!isStripeConnected ? (
        <div className={css.description}>
          <p className={css.descriptionText}>
            <FormattedMessage
              id="EditListingPayoutDetailsPanel.payoutDescription"
              values={{
                payoutLink: (
                  <NamedLink name="HowItWorksPage">
                    <FormattedMessage id="EditListingPayoutDetailsPanel.payoutLink" />
                  </NamedLink>
                ),
              }}
            />
          </p>
        </div>
      ) : null}
      <StripeBox
        intl={intl}
        currentUserLoaded={currentUserLoaded}
        isStripeConnected={isStripeConnected}
      >
        {stripeConnectForm}
      </StripeBox>
      <Button
        className={css.payoutButton}
        inProgress={payoutButtonInProgress}
        disabled={payoutButtonDisabled}
        onClick={() => {
          const updatedValues = {
            publicData: { passedPayout: true },
          };

          onSubmit(updatedValues);
        }}
      >
        {submitButtonText}
      </Button>
    </div>
  );
};

EditListingPayoutDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
};

EditListingPayoutDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,
};

export default EditListingPayoutDetailsPanel;
