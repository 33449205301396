import React, { Component } from 'react';
import { func, object, string, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import moment from 'moment-timezone';
import { IconSpinner } from '../../components';

import css from './FieldDateAndTimeInput.module.css';

/////////////////////////////////////
// FieldDateAndTimeInput component //
/////////////////////////////////////
class FieldDateAndTimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSlotIndex: null,
    };
  }

  render() {
    const {
      rootClassName,
      className,
      miniSessionTimeSlots,
      fetchMiniSessionTimeSlotsInProgress,
      timeZone,
    } = this.props;

    // Handle clicking the timeslot
    const handleClick = (ts, index) => {
      const { form, setTimeslotSelected } = this.props;
      this.setState({ selectedSlotIndex: index });
      setTimeslotSelected();

      const {
        attributes: { start, end },
      } = ts;
      form.batch(() => {
        form.change(
          'bookingStartTime',
          moment(start)
            .tz(timeZone)
            .valueOf()
        );
        form.change(
          'bookingEndTime',
          moment(end)
            .tz(timeZone)
            .valueOf()
        );
      });
    };

    const classes = classNames(rootClassName || css.root, className);

    return !fetchMiniSessionTimeSlotsInProgress ? (
      <div className={classes}>
        {miniSessionTimeSlots && miniSessionTimeSlots?.length > 0 ? (
          <div className={css.formRow}>
            <h3 className={css.timeslotsTitle}>
              <FormattedMessage id="FieldDateAndTimeInput.timeslotsTitle" />
            </h3>
            <div className={css.timeslots}>
              {miniSessionTimeSlots?.map((ts, index) => {
                const {
                  attributes: { start },
                } = ts;
                const timeslotClasses = classNames(css.timeslot, {
                  [css.activeTimeslot]: index === this.state.selectedSlotIndex,
                });
                return (
                  <div
                    key={index}
                    className={timeslotClasses}
                    onClick={() => handleClick(ts, index)}
                  >
                    {moment.tz(start, timeZone).format('hh:mm A')}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          !fetchMiniSessionTimeSlotsInProgress && (
            <p className={css.error}>
              <FormattedMessage id="FieldDateAndTimeInput.noTimeslots" />
            </p>
          )
        )}
      </div>
    ) : (
      <div className={css.spinnerContainer}>
        <IconSpinner />
      </div>
    );
  }
}

FieldDateAndTimeInput.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

FieldDateAndTimeInput.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  bookingStartLabel: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  miniSessionTimeSlots: array,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default FieldDateAndTimeInput;
