import React from 'react';
import { PropTypes } from 'prop-types';

const IconArrow = props => {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 407.436 407.436"
    >
      <polygon points="112.814,0 91.566,21.178 273.512,203.718 91.566,386.258 112.814,407.436 315.869,203.718 " />
    </svg>
  );
};

IconArrow.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconArrow.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconArrow;
