import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, userFirstNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import truncate from 'lodash/truncate';
import moment from 'moment';

import css from './MiniSessionCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MIN_LENGTH_FOR_LOCATION = 50;

const truncated = s => {
  return truncate(s, {
    length: MIN_LENGTH_FOR_LOCATION,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'MiniSessionCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'MiniSessionCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const MiniSessionCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    infoClass,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description, price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorFirstName = userFirstNameAsString(author, '');
  const authorName = <span className={css.authorName}>{authorFirstName}</span>;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const turnaroundTimeOptions = config.custom.turnaroundTime;

  const { formattedPrice } = priceData(price, intl);

  // Fetch event publicData
  const sessionDate = publicData?.session_date;
  const sessionLength = publicData?.session_length;
  const sessionDigitalFiles = publicData?.digitalFiles;
  const sessionTurnaroundTime = publicData?.turnaroundTime
    ? turnaroundTimeOptions.find(time => time.key === publicData?.turnaroundTime).label
    : null;
  const sessionLocationCity = publicData?.locationCity;
  const sessionLocation = sessionLocationCity ? sessionLocationCity : publicData?.location?.address;
  const sessionAvailability = publicData?.availabilityOn;

  const formattedSessionLocation = truncated(sessionLocation);
  const isInstantBooking = sessionAvailability && sessionAvailability === 'currentWebsite';

  // Event dates
  const startDay = sessionDate && moment.utc(sessionDate).format('D');
  const startMonth = sessionDate && moment.utc(sessionDate).format('MMM');

  return (
    <NamedLink
      className={classes}
      name="ListingPage"
      params={{ id, slug }}
      onMouseEnter={() => setActiveListing(currentListing.id)}
      onMouseLeave={() => setActiveListing(null)}
    >
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        {sessionDate ? (
          <div className={css.date}>
            <div className={css.day} title="Jun">
              {startDay}
            </div>
            <div className={css.month}>{startMonth}</div>
          </div>
        ) : null}
        <div className={classNames(infoClass && infoClass, css.mainInfo)}>
          <div className={css.titleWrapper}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {isInstantBooking ? (
              <span className={css.instantBook}>
                <FormattedMessage id="MiniSessionCard.instantBook" />
              </span>
            ) : null}
          </div>
          <div className={css.authorInfo}>
            <div>
              {sessionLength ? (
                <span className={css.length}>
                  <FormattedMessage
                    id="MiniSessionCard.sessionLength"
                    values={{ length: sessionLength }}
                  />
                </span>
              ) : null}
              {sessionDigitalFiles ? (
                <span className={css.files}>
                  <FormattedMessage
                    id="MiniSessionCard.sessionDigitalFiles"
                    values={{ files: sessionDigitalFiles }}
                  />
                </span>
              ) : null}
              {sessionTurnaroundTime ? (
                <span className={css.time}>
                  <FormattedMessage
                    id="MiniSessionCard.sessionTurnarundTime"
                    values={{ time: sessionTurnaroundTime }}
                  />
                </span>
              ) : null}
              {sessionLocation ? (
                <span className={css.mobileLocation}>{formattedSessionLocation}</span>
              ) : null}
            </div>
            {sessionLocation ? (
              <span className={css.location}>{formattedSessionLocation}</span>
            ) : null}
          </div>
          <div className={css.bio}>
            <p className={css.description}>{description}</p>
          </div>
          <div className={css.bottomCard}>
            <div className={css.listingInfo}>
              {startDay && startMonth ? (
                <span className={css.mobileDate}>
                  <FormattedMessage
                    id="MiniSessionCard.mobileDate"
                    values={{ startDay, startMonth }}
                  />
                </span>
              ) : null}
              <span>{formattedPrice}</span>
              <span className={css.postedBy}>
                <FormattedMessage id="MiniSessionCard.hostedBy" values={{ authorName }} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

MiniSessionCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

MiniSessionCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(MiniSessionCardComponent);
