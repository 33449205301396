import React from 'react';
import { string } from 'prop-types';

const IconPhotography = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="24"
      height="24"
      {...props}
    >
      <path
        fill="#c8e7f9"
        d="M467.816 408.093l-23.295 1.935-283.543 23.487-30.757-371.184-2.437-29.41L434.622 7.499z"
      />
      <path
        d="M444.521 410.028l-283.543 23.487-30.757-371.184 219.775-42.602c10.038-1.946 19.756 4.618 21.702 14.657l72.823 375.642z"
        fill="#b5dff6"
      />
      <path
        fill="#6b7f9e"
        d="M428.069 257.453l-12.915 1.069-239.766 19.863-18.334-221.25-2.191-26.459 252.68-20.931z"
      />
      <path
        d="M415.154 258.522l-239.766 19.863-18.334-221.25 192.942-37.406c10.038-1.946 19.756 4.618 21.702 14.657l43.456 224.136z"
        fill="#586c89"
      />
      <path fill="#c8e7f9" d="M422.93 445.898l-302.248 58.59L44.19 109.894l302.248-58.59z" />
      <path fill="#b5dff6" d="M422.939 445.91L120.691 504.5l-5.153-26.583 302.248-58.59z" />
      <path fill="#00e7f1" d="M367.051 300.467l-248.894 48.248L70.86 104.719l248.894-48.247z" />
      <path fill="#00f7fd" d="M140.125 344.452l-21.966 4.258-47.298-243.995 21.966-4.258z" />
      <path fill="#00d8de" d="M367.072 300.469l-21.966 4.258L297.808 60.73l21.966-4.258z" />
      <path
        d="M232.621 273l-.015-.01c-1.528-.988-112.667-73.387-93.799-114.563 19.374-42.283 71.466 4.57 72.688 5.67.015.01.018.023.018.023s31.126-64.807 65.299-32.447C309.926 163.033 232.621 273 232.621 273z"
        fill="#ff90c1"
      />
      <path
        d="M232.619 272.999l-.01-.01c-1.49-.96-106.4-69.3-95.1-110.95 20.27 39.17 89.19 84.05 90.39 84.83l.01.01s47.27-67.23 52.05-111.54c24.52 35.44-47.34 137.66-47.34 137.66z"
        fill="#ff73af"
      />
      <path d="M475.29 407.474l-17.028-205.502c-.343-4.128-3.985-7.191-8.094-6.855a7.5 7.5 0 00-6.855 8.094l16.409 198.027-37.201 3.081-3.302-17.035 5.441-.452a7.5 7.5 0 006.854-8.095c-.343-4.129-3.974-7.195-8.096-6.854l-7.071.587-2.177-11.227 8.317-.689a7.5 7.5 0 10-1.239-14.949l-9.95.824-15.275-78.792 32.666-2.709a7.5 7.5 0 006.854-8.094L415.636 16.598l12.13-1.005 12.386 149.473a7.5 7.5 0 0014.949-1.239L442.096 6.88c-.342-4.128-3.963-7.189-8.094-6.855L154.265 23.201l-.023.001-.023.003-27.055 2.242a7.5 7.5 0 00-6.855 8.093l4.396 53.095L69.433 97.35h-.002l-.003.001-26.672 5.171a7.497 7.497 0 00-5.935 8.791l39.589 204.21a7.495 7.495 0 008.791 5.936 7.5 7.5 0 005.936-8.79L52.973 115.82l11.949-2.316 45.877 236.645a7.503 7.503 0 008.791 5.935l182.042-35.291a7.5 7.5 0 00-2.855-14.726l-174.679 33.864-44.45-229.283L313.83 65.249l44.451 229.283-27.663 5.363a7.5 7.5 0 002.855 14.725l35.025-6.79a7.497 7.497 0 005.935-8.791L328.557 62.394l11.949-2.316 73.647 379.889-287.532 55.742-28.773-148.414a7.496 7.496 0 00-8.791-5.936 7.5 7.5 0 00-5.936 8.79l30.2 155.777a7.503 7.503 0 008.791 5.935l302.258-58.597a7.497 7.497 0 005.935-8.791l-4.913-25.344 43.042-3.565a7.504 7.504 0 006.856-8.09zM135.876 39.776l12.132-1.005 3.539 42.66-12.028 2.332-3.643-43.987zm209.137 4.149L166.361 78.559l-3.403-41.027 237.729-19.696 19.288 232.762-26.824 2.224-39.347-202.961a7.497 7.497 0 00-8.791-5.936z" />
      <path d="M251.147 115.652c-19.62 3.619-34.674 24.685-41.886 36.88-11.247-8.617-33.084-22.526-52.633-18.556-7.479 1.521-17.824 6.455-24.636 21.326-9.395 20.505 4.587 47.931 41.555 81.515 25.979 23.601 53.817 41.724 54.99 42.483a7.496 7.496 0 0010.215-1.98c.804-1.143 19.854-28.355 35.132-59.955 21.739-44.965 24.459-75.628 8.083-91.138-11.877-11.25-23.32-11.961-30.82-10.575zm20.506 21.465c10.763 10.194 6.784 36.323-11.204 73.574-10.594 21.939-23.409 42.167-29.851 51.908-9.587-6.609-28.951-20.521-46.965-36.886-30.69-27.881-44.187-50.668-38.004-64.165 3.406-7.436 7.977-11.646 13.973-12.87a20.867 20.867 0 014.188-.404c15.58 0 35.684 15.035 42.661 21.384a7.506 7.506 0 0011.815-2.285c4.48-9.288 19.614-34.036 35.617-36.973 6.016-1.108 11.83 1.092 17.77 6.717zM369.865 388.841l-60.172 11.666a7.5 7.5 0 002.855 14.725l60.172-11.666a7.5 7.5 0 00-2.855-14.725zM374.883 414.729l-21.689 4.205a7.5 7.5 0 002.855 14.725l21.689-4.205a7.5 7.5 0 005.936-8.79 7.494 7.494 0 00-8.791-5.935z" />
    </svg>
  );
};

IconPhotography.defaultProps = {
  className: null,
};

IconPhotography.propTypes = {
  className: string,
};

export default IconPhotography;
