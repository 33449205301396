import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { IconTooltip } from '../../components';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const {
    id,
    idForLabel,
    rootClassName,
    className,
    tipClass,
    iconClass,
    label,
    message,
    place,
  } = props;
  // Classes
  const classes = classNames(rootClassName || css.root, className);
  const tipClasses = tipClass ? tipClass : css.tooltip;
  const iconClasses = iconClass ? iconClass : css.icon;
  return (
    <div className={classes}>
      {label ? <label htmlFor={idForLabel ? idForLabel : id}>{label}</label> : null}
      <IconTooltip id={id} message={message} className={iconClasses} />
      <ReactTooltip
        clickable={true}
        className={tipClasses}
        id={id}
        uuid={id}
        place={place ? place : 'top'}
      />
    </div>
  );
};

Tooltip.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

Tooltip.propTypes = {
  rootClassName: string,
  className: string,
};

export default Tooltip;
