import React from 'react';
import {
  TRANSITION_COMPLETE,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  txIsDelivered,
  txIsReviewed,
  txIsInFirstReviewBy,
  txRoleIsCustomer,
  getUserTxRole,
} from '../../util/transaction';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton } from '../Button/Button';

import css from './TransactionPanel.module.css';

const hasUserLeftAReviewFirst = (userRole, transaction) => {
  // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
  // we should also use isCustomer insted of isProvider
  const isCustomer = txRoleIsCustomer(userRole);
  return txIsInFirstReviewBy(transaction, isCustomer);
};

const isOwnTransition = (transitions, transition, ownRole) => {
  const currentTransition = transitions.find(t => t.transition === transition);
  const isOwn = currentTransition.by === ownRole;

  return isOwn;
};

const LeaveReviewButton = props => {
  const { currentTransaction, currentUser, onOpenReviewModal } = props;

  const lastTransition = currentTransaction.attributes.lastTransition;
  const ownRole = getUserTxRole(currentUser.id, currentTransaction);

  const leaveReviewButtonFn = () => {
    switch (lastTransition) {
      case TRANSITION_COMPLETE:
        // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
        const reviewPeriodJustStarted = txIsDelivered(currentTransaction);
        const reviewAsFirstLink = reviewPeriodJustStarted ? (
          <div className={css.leaveReviewButton}>
            <PrimaryButton onClick={onOpenReviewModal}>
              <FormattedMessage id="TransactionPanel.leaveReview" />
            </PrimaryButton>
          </div>
        ) : null;

        return reviewAsFirstLink;
      case TRANSITION_REVIEW_1_BY_PROVIDER:
        if (
          isOwnTransition(
            currentTransaction.attributes.transitions,
            TRANSITION_REVIEW_1_BY_PROVIDER,
            ownRole
          )
        ) {
          return null;
        } else {
          // show the leave a review link if current user is not the first
          // one to leave a review
          const reviewPeriodIsOver = txIsReviewed(currentTransaction);
          const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, currentTransaction);
          const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
            <div className={css.leaveReviewButton}>
              <PrimaryButton onClick={onOpenReviewModal}>
                <FormattedMessage id="TransactionPanel.leaveReview" />
              </PrimaryButton>
            </div>
          ) : null;

          return reviewAsSecondLink;
        }
      case TRANSITION_REVIEW_1_BY_CUSTOMER:
        if (
          isOwnTransition(
            currentTransaction.attributes.transitions,
            TRANSITION_REVIEW_1_BY_CUSTOMER,
            ownRole
          )
        ) {
          return null;
        } else {
          // show the leave a review link if current user is not the first
          // one to leave a review
          const reviewPeriodIsOver = txIsReviewed(currentTransaction);
          const userHasLeftAReview = hasUserLeftAReviewFirst(ownRole, currentTransaction);
          const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
            <PrimaryButton onClick={onOpenReviewModal}>
              <FormattedMessage id="TransactionPanel.leaveReview" />
            </PrimaryButton>
          ) : null;
          return reviewAsSecondLink;
        }
      default:
        return null;
    }
  };

  const leaveReviewButtonMaybe = leaveReviewButtonFn();
  return leaveReviewButtonMaybe;
};

export default LeaveReviewButton;
