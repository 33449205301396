import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import IconFind from './IconFind';
import IconPhotoshoot from './IconPhotoshoot';
import IconImages from './IconImages';
import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.steps}>
        <div className={css.firstStep}>
          <h2 className={css.stepBigTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" values={{ break: <br /> }} />
          </h2>
        </div>

        <div className={css.step}>
          <IconFind className={css.stepIcon} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <IconPhotoshoot className={css.stepIcon} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>

        <div className={css.step}>
          <IconImages className={css.stepIcon} />
          <h3 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part4Title" />
          </h3>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part4Text" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
