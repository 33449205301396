import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry, IconSubmit } from '../../components';

import css from './NewsletterForm.module.css';

const NewsletterFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        newsletterSuccess,
        newsletterError,
        newsletterMessage,
        intl,
        values,
      } = fieldRenderProps;

      const emailPlaceholder = intl.formatMessage({
        id: 'NewsletterForm.emailPlaceholder',
      });
      const newsletterErrorMessage = intl.formatMessage(
        {
          id: 'NewsletterForm.newsletterError',
        },
        {
          message: newsletterMessage,
        }
      );

      const showNewsletterError = newsletterError ? (
        <p
          className={css.error}
          dangerouslySetInnerHTML={{
            __html: newsletterErrorMessage,
          }}
        />
      ) : null;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || !values?.email;

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconEnquiry className={css.icon} />
          <h2 className={css.heading}>
            <FormattedMessage id="NewsletterForm.heading" />
          </h2>
          <p className={css.text}>
            And find out about upcoming Mini Sessions in your area as well as promotions
          </p>
          {newsletterSuccess ? (
            <p className={css.success}>
              <FormattedMessage
                id="NewsletterForm.newsletterSuccess"
                values={{ message: newsletterMessage }}
              />
            </p>
          ) : (
            <div className={css.newsletter}>
              <FieldTextInput
                className={css.field}
                type="text"
                name="email"
                id={formId ? `${formId}.email` : 'email'}
                placeholder={emailPlaceholder}
                marketplaceColorInput
              />
              <div className={css.submitButtonWrapper}>
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  newsletterInProgress
                >
                  <IconSubmit className={css.submitIcon} />
                </PrimaryButton>
              </div>
            </div>
          )}
          {showNewsletterError}
        </Form>
      );
    }}
  />
);

NewsletterFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  newsletterError: null,
};

NewsletterFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  newsletterError: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const NewsletterForm = compose(injectIntl)(NewsletterFormComponent);

NewsletterForm.displayName = 'NewsletterForm';

export default NewsletterForm;
