import React from 'react';

const IconPhotos = props => {
  return (
    <svg
      height="24"
      viewBox="41.64 164.945 512 512"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M468.408 301.131L194.29 189.181l-83.268 203.852-5.558 13.696 13.795 5.657 5.558-13.794 77.611-189.958 226.579 92.497H207.391v302.504L64.873 545.377l33.048-80.986-6.847-2.778 6.847 2.778 4.367-10.618-13.696-5.657-4.366 10.718-38.707 94.681 161.872 66.198v33.346H551.28V412.982h-14.888v225.191H222.277V316.018h314.115V368.322h14.888v-67.191z" />
      <path d="M536.392 383.208h14.888v14.887h-14.888zM113.499 426.242l-5.628 13.786-13.786-5.628 5.627-13.786zM252.25 605.222H506.42v-254.27H252.25zm101.331-14.886L420.77 468.56l17.567 35.431h-31.064v14.888h38.408l35.53 71.557h-127.63zM491.533 365.84v211.693l-70.167-141.129-84.856 153.932h-54.387l67.289-122.073 15.384 29.873 13.2-6.848-28.188-54.784-82.671 150.358V365.84z" />
      <path d="M450.644 433.724c15.779 0 28.582-12.803 28.582-28.583s-12.803-28.583-28.582-28.583c-15.781 0-28.584 12.803-28.584 28.583s12.803 28.583 28.584 28.583zm0-42.278c7.542 0 13.696 6.153 13.696 13.696 0 7.542-6.154 13.696-13.696 13.696-7.544 0-13.696-6.153-13.696-13.696s6.152-13.696 13.696-13.696z" />
    </svg>
  );
};

export default IconPhotos;
