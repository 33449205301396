import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink, NamedLink } from '../../components';
import { EditListingBusinessDetailsForm } from '../../forms';
import css from './EditListingBusinessPanel.module.css';

const EditListingBusinessDetailsPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    params,
  } = props;

  const [initialProps, setInitialProps] = useState({});
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, privateData, title } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingBusinessDetailsPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage
              id="EditListingBusinessDetailsPanel.listingTitle"
              values={{ listingTitle: title }}
            />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingBusinessDetailsPanel.createListingTitle" />
  );
  let initialValues = {
    businessId: privateData?.yelp_business_id,
  };

  if (updateInProgress) {
    initialValues = { ...initialValues, ...initialProps };
  }

  const isYelpConnected = publicData?.has_yelp;
  const yelpBusinessId = privateData?.yelp_business_id;

  const faqLink = (
    <NamedLink
      name="FAQPage"
      params={{
        id: 'yelp-credentials',
      }}
      className={css.link}
    >
      <FormattedMessage id="EditListingBusinessDetailsPanel.faqLink" />
    </NamedLink>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>

      <div className={css.yelpInfo}>
        <label>
          <FormattedMessage id="EditListingBusinessDetailsPanel.yelpInfoLabel" />
        </label>
        <p className={css.yelpInfoText}>
          <FormattedMessage
            id="EditListingBusinessDetailsPanel.yelpInfoText"
            values={{ faqLink }}
          />
        </p>
      </div>
      <EditListingBusinessDetailsForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { businessId } = values;
          let has_yelp = businessId ? true : false;
          const updateValues = {
            publicData: {
              has_yelp,
            },
            privateData: {
              yelp_business_id: businessId ?? null,
            },
          };
          setInitialProps({
            ...updateValues.publicData,
            businessId: updateValues.privateData.yelp_business_id,
          });
          onSubmit(updateValues);
        }}
        publicData={publicData}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        params={params}
        isYelpConnected={isYelpConnected}
        yelpBusinessId={yelpBusinessId}
      />
    </div>
  );
};

EditListingBusinessDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingBusinessDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBusinessDetailsPanel;
