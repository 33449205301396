import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../components';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    isCurrentUserClient,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    isLandingPage,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className, {
    [css.landingPageRoot]: isLandingPage,
  });

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage" disabled={isCurrentUserClient}>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <div className={css.inboxWithNotification}>
              <FormattedMessage id="TopbarDesktop.inbox" />
              {notificationDot}
            </div>
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const createListingLink =
    currentUser && isCurrentUserClient ? null : (
      <NamedLink className={css.createListingLink} name="SelectCategoryPage">
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
    );

  const miniSessionsLink = (
    <NamedLink
      className={css.navigationLink}
      name="SearchPage"
      to={{
        search: '?pub_category=mini_session',
      }}
    >
      <span className={css.navigationItem}>
        <FormattedMessage id="TopbarDesktop.miniSessions" />
      </span>
    </NamedLink>
  );

  const howItWorksLink = isAuthenticated ? (
    <NamedLink
      className={classNames(css.largeDeviceLink, css.navigationLink)}
      name="HowItWorksPage"
    >
      <span className={css.navigationItem}>
        <FormattedMessage id="TopbarDesktop.howItWorks" />
      </span>
    </NamedLink>
  ) : null;

  const findPhotographerLink = (
    <NamedLink
      className={css.navigationLink}
      name="SearchPage"
      to={{ search: '?pub_category=photographer' }}
    >
      <span className={css.navigationItem}>
        <FormattedMessage id="TopbarDesktop.findPhotographer" />
      </span>
    </NamedLink>
  );

  const forPhotographersLink =
    isAuthenticated && !isCurrentUserClient ? (
      <NamedLink
        className={classNames(css.largeDeviceLink, css.navigationLink)}
        name="PhotographersPage"
      >
        <span className={css.navigationItem}>
          <FormattedMessage id="TopbarDesktop.forPhotographers" />
        </span>
      </NamedLink>
    ) : null;

  return (
    <nav className={classes}>
      <div className={css.navWrapper}>
        <NamedLink className={css.logoLink} name="LandingPage">
          <Logo
            format="desktop"
            className={classNames(css.logo, {
              [css.landingPageLogo]: isLandingPage,
            })}
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
            isLandingPage={isLandingPage}
          />
        </NamedLink>
        <div className={css.menuWrapper}>
          {createListingLink}
          {howItWorksLink}
          {findPhotographerLink}
          {forPhotographersLink}
          {miniSessionsLink}
          {profileMenu}
          {signupLink}
          {loginLink}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
