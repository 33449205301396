import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import Switch from 'react-switch';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    isMapVisible,
    onToggleMap,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);
  const filtersWrapperClasses = classNames(css.filtersWrapper, {
    [css.filtersWrapperWithoutMap]: !isMapVisible,
  });

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;

  // By default we are going to hide the
  // more filters button
  const showSecondaryFiltersButton = false;
  const toggleSecondaryFiltersOpenButton =
    showSecondaryFiltersButton && toggleSecondaryFiltersOpen ? (
      <button
        className={toggleSecondaryFiltersOpenButtonClasses}
        onClick={() => {
          toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
        }}
      >
        <FormattedMessage
          id="SearchFiltersPrimary.moreFiltersButton"
          values={{ count: selectedSecondaryFiltersCount }}
        />
      </button>
    ) : null;

  return (
    <div className={classes}>
      <div className={filtersWrapperClasses}>
        <div className={css.filters}>
          {sortByComponent}
          {children}
          {toggleSecondaryFiltersOpenButton}
        </div>
        <div className={css.mapOptions}>
          <span className={css.showMap}>
            <FormattedMessage id="SearchFiltersPrimary.showMap" />
          </span>
          <Switch
            id="map-switch"
            onChange={onToggleMap}
            checked={isMapVisible}
            onColor="#f19b8c"
            onHandleColor="#fff"
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            handleDiameter={25}
            height={20}
            width={51}
          />
        </div>
      </div>

      <div className={css.filtersSeperator} />

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
