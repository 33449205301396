import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { ExternalLink } from '../../components';

import css from './SectionBlog.module.css';

import firstImage from './images/blog-1-photo.jpg';
import secondImage from './images/blog-2-photo.jpg';
import thirdImage from './images/blog-3-photo.jpg';
import fourthImage from './images/blog-4-photo.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const blogLink = (name, post, image, slug) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <ExternalLink
      href={`https://www.picturehummable.com/picturehumsessions/${slug}`}
      className={css.location}
    >
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        {nameText}
        <p className={css.linkPost}>{post}</p>
      </div>
    </ExternalLink>
  );
};

const SectionBlog = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionBlog.title" />
      </div>
      <div className={css.locations}>
        {blogLink(
          'Top 5 Locations for Family Photos in the San Francisco Bay Area',
          'There are so many beautiful outdoor spots for photography in the Bay Area...',
          firstImage,
          'top-5-locations-for-family-photos-in-the-san-francisco-bay-area'
        )}
        {blogLink(
          'PictureHum San Francisco session with Kristen',
          'I couldn’t wait to share this fabulous recent PictureHum session photographed...',
          fourthImage,
          'picturehum-san-francisco-session-with-kristen'
        )}
        {blogLink(
          'Bay Area session with Juliet',
          'Here in the Bay Area the weather is wintery cold and we’ve had a ton of rain...',
          secondImage,
          'picturehum-bay-area-session-with-juliet'
        )}
        {blogLink(
          'Beach session with Brittney',
          'We just love a beach family photo session, and we especially love one where...',
          thirdImage,
          'picturehum-beach-session-with-brittney'
        )}
      </div>
    </div>
  );
};

SectionBlog.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionBlog.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBlog;
