import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconPhotography } from '../../components';

import css from './SectionIntro.module.css';

const SectionIntro = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <IconPhotography className={css.icon} />
      <div className={css.title}>
        <FormattedMessage id="SectionIntro.title" />
      </div>
      <p className={css.text}>
        <FormattedMessage id="SectionIntro.text" />
      </p>
    </div>
  );
};

SectionIntro.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionIntro.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionIntro;
