import React, { useState } from 'react';
import { IconArrowHead } from '../../components';
import classNames from 'classnames';

import IconPhotos from './IconPhotos';
import IconTime from './IconTime';
import css from './Accordion.module.css';

const DEFAULT_ARROW_DIRECTION = 'down';
const DEFAULT_ICON = 'digital-files';

const AccordionItem = props => {
  const { id, label, content } = props;

  const [isExpanded, expand] = useState(false);

  // Classes
  const contentClasses = classNames(css.content, {
    [css.contentExpanded]: isExpanded,
  });
  const arrowClasses = classNames(css.arrow, {
    [css.arrowExpanded]: isExpanded,
  });

  return (
    <div className={css.accordionItem} onClick={() => expand(!isExpanded)}>
      <div className={css.header}>
        <div className={css.headerWrapper}>
          {DEFAULT_ICON === id ? (
            <IconPhotos className={css.icon} />
          ) : (
            <IconTime className={css.icon} />
          )}
          {label}
        </div>
        <IconArrowHead className={arrowClasses} direction={DEFAULT_ARROW_DIRECTION} />
      </div>
      <div className={contentClasses}>
        <div className={css.contentDescription}>{content}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
