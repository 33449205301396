import React from 'react';
import classNames from 'classnames';
import AccordionItem from './AccordionItem';

import css from './Accordion.module.css';

const Accordion = props => {
  const { rootClassName, className, accordions } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {accordions.map(accordion => {
        return <AccordionItem key={accordion.key} id={accordion.key} {...accordion} />;
      })}
    </div>
  );
};

export default Accordion;
