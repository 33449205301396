import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { userLocation } from '../../util/maps';
import { createResourceLocatorString } from '../../util/routes';
import { Button } from '../../components';
import { locationBounds } from '../../util/googleMaps';

import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import classNames from 'classnames';

import sectionImage from '../../assets/sessions-image.jpg';
import css from './SectionMiniSessions.module.css';

const SectionMiniSessions = props => {
  const { rootClassName, className, history } = props;

  const handleClick = () => {
    return userLocation().then(latlng => {
      const searchParams = {
        address: '',
        origin: latlng,
        bounds: locationBounds(latlng, config.maps.search.currentLocationBoundsDistance),
        pub_category: 'mini_session',
      };

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
      );
    });
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sessionsContentWrapper}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionMiniSessions.title" />
        </h2>
        <div className={css.textWrapper}>
          <p className={css.text}>
            <FormattedMessage
              id="SectionMiniSessions.firstText"
              values={{
                miniSessions: <strong>Mini Sessions</strong>,
              }}
            />
          </p>
          <p className={css.text}>
            <FormattedMessage
              id="SectionMiniSessions.secondText"
              values={{
                miniSesh: <strong>Mini Sesh Board</strong>,
              }}
            />
          </p>
        </div>
        <Button className={css.button} onClick={handleClick}>
          <FormattedMessage id="SectionMiniSessions.button" />
        </Button>
      </div>
      <div className={css.sessionsImageWrapper}>
        <img className={css.image} src={sectionImage} alt="Mini Sessions" />
      </div>
    </div>
  );
};

export default SectionMiniSessions;
