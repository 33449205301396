import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, MiniSessionPayout } from '../../components';
import { EditListingLocationForm } from '../../forms';
import { getAddressBounds, getCityFromBounds } from '../../util/googleMaps';

import css from './EditListingLocationPanel.module.css';

const categoryType = 'mini_session';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);
    this.setPredictionAddress = this.setPredictionAddress.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
      predictionAddress: null,
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent = publicData?.location?.address && geolocation;
    const location = publicData?.location ? publicData.location : {};
    const customAddress = publicData?.customAddress;
    const { address } = location;

    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation },
          }
        : null,
      customAddress,
    };
  }

  setPredictionAddress(address) {
    // Get the bounds from the given
    // address description
    return getAddressBounds(address).then(bounds =>
      // Then get the city name from the
      // given bounds
      getCityFromBounds(bounds).then(city => {
        this.setState({
          predictionAddress: city,
        });
      })
    );
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      onSubmit,
      ready,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { title } = currentListing?.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage
                id="EditListingLocationPanel.listingTitle"
                values={{ listingTitle: title }}
              />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );

    const { publicData } = currentListing.attributes;
    const category = publicData.category || params.category;
    const isMiniSession = category === categoryType;

    const miniSession = publicData?.category === 'mini_session';
    const paid = currentListing?.attributes?.metadata?.publishable;

    return miniSession && !paid ? (
      paid ? null : (
        <MiniSessionPayout currentListing={currentListing} publicData={publicData} />
      )
    ) : (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>

        <EditListingLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const { location, customAddress } = values;
            const {
              selectedPlace: { address, origin },
            } = location;

            const city = this.state.predictionAddress || publicData?.locationCity;

            const updateValues = {
              geolocation: origin,
              publicData: {
                location: { address },
                customAddress,
                locationCity: city,
              },
            };
            this.setState({
              initialValues: {
                location: { search: address, selectedPlace: { address, origin } },
                customAddress,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          isMiniSession={isMiniSession}
          setPredictionAddress={this.setPredictionAddress}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
