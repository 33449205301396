import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { validateYelp } from '../../util/api';
import arrayMutators from 'final-form-arrays';
import {
  Form,
  Button,
  FieldTextInput,
  SecondaryButton,
  InlineTextButton,
  IconSuccess,
  IconEdit,
  IconYelp,
} from '../../components';

import css from './EditListingBusinessDetailsForm.module.css';

const YELP_API_KEY = process.env.REACT_APP_YELP_API;

const EditListingBusinessDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        isYelpConnected,
      } = formRenderProps;

      const [verified, setVerified] = useState(isYelpConnected);
      const [loading, setLoading] = useState(false);
      const [errorYelp, setErrorYelp] = useState(false);
      const [editAccount, setEditAccount] = useState(false);

      const businessIdMessage = intl.formatMessage({
        id: 'EditListingBusinessForm.BusinessIdtitle',
      });
      const businessIdPlaceholderMessage = intl.formatMessage({
        id: 'EditListingBusinessForm.businessIdPlaceholder',
      });

      const verifiedMessage = isYelpConnected
        ? intl.formatMessage({ id: 'EditListingBusinessForm.updateMessage' })
        : intl.formatMessage({ id: 'EditListingBusinessForm.verifiedMessage' });
      const errorMessage = intl.formatMessage({ id: 'EditListingBusinessForm.errorMessage' });
      let errorYelpMessage = errorYelp ? errorMessage : null;

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;
      const isBusinessIdYelpKeySelected = item => {
        if (item.businessId) {
          return true;
        } else {
          return false;
        }
      };

      function handleChange(value) {
        const businessId = value.businessId;

        setLoading(true);
        setErrorYelp(null);

        validateYelp({ businessId, token: YELP_API_KEY })
          .then(response => {
            setLoading(false);
            setVerified(true);
            setEditAccount(false);
          })
          .catch(err => {
            setLoading(false);
            setErrorYelp(true);
            console.log('err', err);
          });
      }
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      let yelpVerificationSuccess;
      if (values?.businessId) {
        yelpVerificationSuccess = values?.businessId && verified;
      } else {
        yelpVerificationSuccess = true;
      }
      const submitDisabled = invalid || disabled || submitInProgress || !yelpVerificationSuccess;
      const submitDisabledYelp = !isBusinessIdYelpKeySelected(values);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="businessId"
            name="businessId"
            className={css.field}
            type="text"
            readOnly={verified && !editAccount}
            label={businessIdMessage}
            placeholder={businessIdPlaceholderMessage}
            autoFocus
          />

          {!editAccount && verified && !loading ? (
            <div className={classNames(css.verificiationBox, css.verficiationSuccessBox)}>
              <div
                className={classNames(
                  css.verificiationBoxTextWrapper,
                  css.verificationBoxSuccessTextWrapper
                )}
              >
                <div className={css.verificationBoxTitle}>
                  <IconSuccess className={css.icon} />
                  <FormattedMessage id="EditListingBusinessDetailsForm.connectedMessage" />
                </div>
              </div>

              <InlineTextButton
                className={css.editVerificationButton}
                spinnerClassName={css.spinner}
                type="button"
                onClick={() => setEditAccount(true)}
              >
                <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />
                <FormattedMessage id="EditListingBusinessDetailsForm.editMessage" />
              </InlineTextButton>
            </div>
          ) : null}

          {!verified || editAccount ? (
            <>
              {errorYelp ? <p className={css.errorMessage}>{errorYelpMessage}</p> : null}
              <SecondaryButton
                className={css.verifyButton}
                type="button"
                disabled={submitDisabledYelp}
                onClick={() => handleChange(values)}
                inProgress={loading}
              >
                <IconYelp className={css.yelpIcon} />
                {verifiedMessage}
              </SecondaryButton>
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingBusinessDetailsFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingBusinessDetailsFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingBusinessDetailsFormComponent);
