import React from 'react';
import { Field } from 'react-final-form';
import { IconSpinner } from '../../components';
const inputStyle = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  padding: '1rem',
  border: '1px solid #ccc',
};

const ACCEPT_FILES = 'image/jpg, image/jpeg, image/png';

const Preview = props => {
  const { image } = props;
  const url = URL.createObjectURL(image);

  return (
    <img
      src={url}
      alt={image.name}
      style={{
        height: '150px',
        objectFit: 'cover',
        objectPosition: 'center',
        width: '100%',
        marginBottom: '12px',
      }}
    />
  );
};

function FieldFileUpload(props) {
  const { className, name, onSelectFile, label, inputLabel, file, uploading, ...restProps } = props;
  return (
    <div className={className}>
      <label style={{ marginBottom: '12px' }}>{label}</label>
      {file ? <Preview image={file} /> : null}
      <label htmlFor={name} style={inputStyle}>
        {uploading ? <IconSpinner /> : inputLabel}
      </label>

      <Field name={name} accept={ACCEPT_FILES}>
        {({ input: { value, onChange, ...rest } }) => {
          const handleChange = event => {
            const file = event.target.files[0];
            onChange(file);
            if (typeof onSelectFile != 'undefined') onSelectFile(file);
          };
          return (
            <input
              {...rest}
              type="file"
              disabled={uploading}
              onChange={handleChange}
              accept={ACCEPT_FILES}
              {...restProps}
              style={{ display: 'none' }}
            />
          );
        }}
      </Field>
    </div>
  );
}

export default FieldFileUpload;
