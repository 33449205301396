import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser, userFirstNameAsString } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, Avatar, IconLocation, IconReviewStar } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import truncate from 'lodash/truncate';
import classNames from 'classnames';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const DESCRIPTION_LENGTH = 100; // two lines

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const truncated = s => {
  return truncate(s, {
    length: DESCRIPTION_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    location,
    removeAvatar,
  } = props;

  const currentListing = ensureListing(listing);
  const author = ensureUser(currentListing?.author);
  const id = currentListing.id.uuid;
  const { title = '', description, price, publicData } = currentListing.attributes;
  const authorFirstName = userFirstNameAsString(author, '');
  const vanitySlug = publicData?.slug;
  const slug = vanitySlug || createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isMiniSession = publicData?.category === 'mini_session';
  const cities = findOptionsForSelectFilter('subCategory', config.custom.filters);

  // Location
  const locationCity = publicData?.locationCity;
  const listingLocation = locationCity
    ? locationCity
    : cities.find(city => city.key === publicData?.subCategory)?.label;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const region = publicData?.subCategory;
  const regionMaybe = region ? { region } : {};

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const dataLabelMessage = isMiniSession
    ? intl.formatMessage({
        id: 'ListingCard.miniSessionMessage',
      })
    : intl.formatMessage({
        id: 'ListingCard.photographerMessage',
      });

  const classes = classNames(rootClassName || css.root, className);
  const avatarClasses = classNames(css.avatar, {
    [css.avatarBorder]: author?.profileImage,
  });

  const prevPath = {
    search: location?.search,
  };

  return (
    <NamedLink
      className={classes}
      name={vanitySlug ? 'ListingPageVanity' : 'ListingPage'}
      params={{ id, slug, ...regionMaybe }}
      to={{ state: { prevPath } }}
    >
      <div
        className={classNames(css.threeToTwoWrapper, css.listingCardBadge)}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
        data-label={dataLabelMessage}
      >
        <div className={css.aspectWrapper}>
          <ListingImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['listing-card', 'listing-card2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {description ? (
            <div className={classNames(css.description, css.longWord)}>
              <p className={css.descriptionText}>{truncated(description)}</p>
            </div>
          ) : null}
          <div className={css.locationAndReviews}>
            {listingLocation ? (
              <div className={css.listingLocation}>
                <IconLocation className={css.locationIcon} />
                <p className={css.location}>{listingLocation}</p>
              </div>
            ) : null}
            <span className={css.divider}>·</span>
            <div className={css.reviews}>
              <div className={css.reviewStars}>
                <IconReviewStar isFilled />
                <IconReviewStar isFilled />
                <IconReviewStar isFilled />
                <IconReviewStar isFilled />
                <IconReviewStar isFilled />
              </div>
              <span className={css.averageReview}>(5.0)</span>
            </div>
          </div>
          {!removeAvatar ? (
            <div className={css.userInfo}>
              <Avatar className={avatarClasses} user={author} />
              <span className={css.displayName}>
                <FormattedMessage
                  id="ListingCard.displayName"
                  values={{
                    displayName: <span className={css.userName}>{authorFirstName}</span>,
                  }}
                />
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
