import React from 'react';
import { string } from 'prop-types';

const IconClipboard = props => {
  const { className } = props;

  return (
    <svg
      height="24"
      width="24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 431.998 431.998"
    >
      <path d="M80.102 192h192v16h-192zM80.102 239.998v16h155.714l16-16zM80.102 287.998v16h107.711l16.001-16zM80.102 336h80v16h-80z" />
      <path d="M411.213 171.106l-22.628-22.626c-6.245-6.245-16.382-6.246-22.627-.001L192.103 322.333v45.662c38.518 0 28.774.018 44.915-.068l83.085-83.083v115.154c0 8.836-7.163 16-16 16h-256c-8.837 0-16-7.164-16-16v-288c0-8.837 7.163-16 16-16h32.003v32.001h191.997v-32l32-.001c8.837 0 16 7.163 16 16v59.426l16-16.111v-43.315c0-17.674-14.327-32-32-32h-34.764c-5.138-14.522-17.041-25.849-31.944-30.134-7.367-2.118-13.037-7.788-15.156-15.155C216.474 14.663 198.001 0 176.102 0c-21.898 0-40.37 14.663-46.134 34.706-2.122 7.376-7.806 13.039-15.181 15.164-14.897 4.293-26.81 15.608-31.942 30.128H48.102c-17.673 0-32 14.326-32 32v288c0 17.673 14.327 32 32 32h256c17.673 0 32-14.327 32-32V268.845c34.399-34.398 66.437-66.437 75.11-75.111 6.245-6.246 6.245-16.383.001-22.628zM96.104 95.742c.11-14.066 9.614-26.606 23.112-30.496 12.71-3.662 22.478-13.426 26.128-26.116C149.261 25.51 161.909 16 176.102 16c14.194 0 26.842 9.51 30.758 23.13 3.652 12.698 13.413 22.459 26.111 26.11 13.618 3.918 23.13 16.566 23.13 30.759v16H96.104V95.742zm134.248 256.223c-4.149.023-5.848.03-11.589.032l-10.661-10.661v-12.375l10.778-10.778 22.627 22.627-11.155 11.155zm22.468-22.468l-22.627-22.627 101.824-101.823 22.627 22.628L252.82 329.497zM365.957 216.36l-22.627-22.627 11.314-11.314 22.628 22.627-11.315 11.314zm22.628-22.626l-22.628-22.628 11.314-11.313 22.628 22.627-11.314 11.314z" />
      <circle cx={176.342} cy={48} r={12} />
    </svg>
  );
};

IconClipboard.defaultProps = {
  className: null,
};

IconClipboard.propTypes = {
  className: string,
};

export default IconClipboard;
