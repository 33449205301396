import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './StaticPageHeader.module.css';

const StaticPageHeader = props => {
  const {
    rootClassName,
    className,
    title,
    subTitle,
    activePage,
    secondLineClass,
    disableNav,
  } = props;

  const classes = rootClassName || classNames(css.root, className);
  return (
    <div className={classes}>
      <div className={css.header}>
        <h1>{title}</h1>
        {subTitle ? <p className={secondLineClass}>{subTitle}</p> : null}
      </div>
      {!disableNav ? (
        <ul className={css.headerLinks}>
          <li>
            <NamedLink
              name="AboutPage"
              className={activePage === 1 ? classNames(css.active, css.pageLink) : css.pageLink}
            >
              <FormattedMessage id="StaticPageHeader.toAboutPage" />
            </NamedLink>
          </li>
          <li>
            <NamedLink
              name="PhotographersPage"
              className={activePage === 2 ? classNames(css.active, css.pageLink) : css.pageLink}
            >
              For Photographers
            </NamedLink>
          </li>
          <li>
            <NamedLink
              name="HowItWorksPage"
              className={activePage === 3 ? classNames(css.active, css.pageLink) : css.pageLink}
            >
              <FormattedMessage id="StaticPageHeader.toHowItWorksPage" />
            </NamedLink>
          </li>
          <li>
            <NamedLink
              name="FAQPage"
              params={{
                id: 'cancellation-policy',
              }}
              className={activePage === 4 ? classNames(css.active, css.pageLink) : css.pageLink}
            >
              <FormattedMessage id="StaticPageHeader.toFAQPage" />
            </NamedLink>
          </li>
          <li>
            <NamedLink
              name="ContactPage"
              className={activePage === 5 ? classNames(css.active, css.pageLink) : css.pageLink}
            >
              <FormattedMessage id="StaticPageHeader.toContactPage" />
            </NamedLink>
          </li>
        </ul>
      ) : null}
    </div>
  );
};

export default StaticPageHeader;
