import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionFeatured.module.css';

import firstImage from './images/medium.png';
import secondImage from './images/tinybeans.png';
import thirdImage from './images/elevate.png';
import forthImage from './images/hunt.png';
import fifthImage from './images/alt.png';

const imageLink = (image, alt) => {
  return <img src={image} alt={alt} className={css.image} />;
};

const SectionFeatured = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionFeatured.title" />
      </div>
      <div className={css.images}>
        {imageLink(secondImage, 'Tiny beans')}
        {imageLink(forthImage, 'Product hunt')}
        {imageLink(fifthImage, 'Alt summit')}
        {imageLink(firstImage, 'Medium')}
        {imageLink(thirdImage, 'Elevate')}
      </div>
    </div>
  );
};

SectionFeatured.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFeatured.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFeatured;
