import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { combinedRelationships, denormalisedResponseEntities } from '../../util/data';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_REVIEWS_REQUEST = 'app/LandingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/LandingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/LandingPage/FETCH_REVIEWS_ERROR';

export const FETCH_LISTING_IMAGES_REQUEST = '/app/LandingPage/FETCH_LISTING_IMAGES_REQUEST';
export const FETCH_LISTING_IMAGES_SUCCESS = 'app/LandingPage/FETCH_LISTING_IMAGES_SUCCESS';
export const FETCH_LISTING_IMAGES_ERROR = 'app/LandingPage/FETCH_LISTING_IMAGES_ERROR';

// ================ Reducer ================ //

const initialState = {
  reviewIds: [],
  reviews: [],
  fetchReviewsInProgress: false,
  fetchReviewsError: null,
  fetchListingImagesInProgress: false,
  fetchListingImagesError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        fetchReviewsInProgress: true,
        fetchReviewsError: null,
        reviewIds: payload.reviewIds,
      };
    case FETCH_REVIEWS_SUCCESS: {
      return {
        ...state,
        fetchReviewsInProgress: false,
        reviews: payload,
      };
    }
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsInProgress: false, fetchReviewsError: payload };

    case FETCH_LISTING_IMAGES_REQUEST:
      return {
        ...state,
        fetchListingImagesError: null,
        fetchListingImagesInProgress: true,
      };
    case FETCH_LISTING_IMAGES_SUCCESS:
      return {
        ...state,
        fetchListingImagesError: null,
        fetchListingImagesInProgress: false,
      };
    case FETCH_LISTING_IMAGES_ERROR:
      return {
        ...state,
        fetchListingImagesError: payload,
        fetchListingImagesInProgress: false,
      };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

const fetchReviewsRequest = reviewIds => ({
  type: FETCH_REVIEWS_REQUEST,
  payload: { reviewIds },
});
const fetchReviewsSuccess = response => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: response,
});
const fetchReviewsError = e => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: e,
});

const fetchListingImagesRequest = () => ({
  type: FETCH_LISTING_IMAGES_REQUEST,
});
const fetchListingImagesSuccess = () => ({
  type: FETCH_LISTING_IMAGES_SUCCESS,
});
const fetchListingImagesError = e => ({
  type: FETCH_LISTING_IMAGES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchListingImages = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchListingImagesRequest());
  return sdk.listings
    .show({
      id: listingId,
      include: ['images'],
      'fields.image': ['variants.landscape-crop'],
      'limit.images': 1,
    })
    .then(response => {
      dispatch(fetchListingImagesSuccess());
      dispatch(addMarketplaceEntities(response));

      // Denormalise listing response
      const listing = denormalisedResponseEntities(response);

      return listing;
    });
};

export const fetchReviews = reviewIds => (dispatch, getState, sdk) => {
  // Fetch users by ID
  const fetchReviewsById = array => {
    // Call request function
    dispatch(fetchReviewsRequest(array));

    const promises = reviewIds.map(async id => {
      return sdk.reviews
        .show({
          id: id,
          include: ['author', 'subject', 'listing', 'images'],
        })
        .then(response => {
          const review = denormalisedResponseEntities(response);
          const listingId = review[0].listing.id.uuid;

          // Fetch listing images
          return dispatch(fetchListingImages(listingId))
            .then(listing => {
              // We need to combine listing response
              // with the review response
              const reviewResponse = review[0];
              const listingResponse = listing[0];

              // Combined responses
              const combinedResponses = combinedRelationships(reviewResponse, {
                listing: {
                  ...listingResponse,
                },
              });

              return combinedResponses;
            })
            .catch(e => dispatch(fetchListingImagesError(storableError(e))));
        })
        .catch(e => dispatch(fetchReviewsError(storableError(e))));
    });
    return Promise.all(promises);
  };

  // Call fetchReviewsById function
  fetchReviewsById(reviewIds)
    .then(function(result) {
      // Call success function
      dispatch(fetchReviewsSuccess(result));
    })
    .catch(e => dispatch(fetchReviewsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Get review ids list from
  // marketplace-custom-config.js
  const reviewIds = config.custom.landingPageReviewsIds;

  return Promise.all([dispatch(fetchReviews(reviewIds))]);
};
