import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink, Carousel } from '../../components';

import css from './SectionLocations.module.css';

import baImage from './images/ba-photo.jpg';
import sdImage from './images/sd-photo.jpg';
import laImage from './images/la-photo.png';
import ncImage from './images/nc-photo.jpg';
import waImage from './images/wa-photo.jpg';
import mpImage from './images/mp-photo.jpg';
import nyImage from './images/ny-photo.jpg';
import auImage from './images/au-photo.jpg';
import poImage from './images/po-photo.jpeg';
import slImage from './images/sl-photo.jpeg';
import chImage from './images/ch-photo.jpeg';
import alImage from './images/al-photo.jpeg';
import boImage from './images/bo-photo.jpeg';
import miImage from './images/mi-photo.jpeg';
import piImage from './images/pi-photo.jpeg';
import phImage from './images/ph-photo.jpeg';
import coImage from './images/co-photo.jpeg';
import naImage from './images/na-photo.jpg';
import seImage from './images/se-photo.jpeg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>{nameText}</div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <Carousel
        className={css.carousel}
        showCarousel={true}
        numberOfCards={{ mobile: 1, desktop: 4 }}
        slidesToScroll={{ mobile: 1, desktop: 2 }}
        gutter={24}
      >
        {locationLink(
          'Bay Area',
          baImage,
          '?address=Bay%20Area%2C%20California%2C%20USA&bounds=38.86424483191087%2C-121.2081780273586%2C36.89415492919909%2C-123.6324969552935'
        )}
        {locationLink(
          'San Diego',
          sdImage,
          '?address=San%20Diego%2C%20California%2C%20United%20States&bounds=33.0722089014065%2C-116.853120012588%2C32.5348710309019%2C-117.262118935776'
        )}
        {locationLink(
          'Los Angeles',
          laImage,
          '?address=Los%20Angeles%2C%20California%2C%20United%20States&bounds=34.1614409628202%2C-118.126644633049%2C33.9018943961945%2C-118.521476933898'
        )}
        {locationLink(
          'Washington DC Area',
          waImage,
          '?address=Washington%2C%20DC%2C%20USA&bounds=39.89417936898627%2C-75.75351370304888%2C38.097548800747234%2C-78.0652722478038'
        )}
        {locationLink(
          'Minneapolis',
          mpImage,
          '?address=Minneapolis%2C%20MN%2C%20USA&bounds=45.94956525049461%2C-91.92219416585847%2C44.15293468225557%2C-94.46539383322114'
        )}
        {locationLink(
          'North Carolina',
          ncImage,
          '?address=North%20Carolina%2C%20United%20States&bounds=36.5881379998642%2C-75.3619017088145%2C33.7514200045103%2C-84.3218215308367'
        )}
        {locationLink(
          'New York',
          nyImage,
          '?address=New%20York%2C%20NY%2C%20USA&bounds=41.81589233482741%2C-72.51144142041414%2C40.01926176658837%2C-74.88910271593844'
        )}
        {locationLink(
          'Austin',
          auImage,
          '?address=Austin%2C%20TX%2C%20USA&bounds=31.41517820277103%2C-96.53032866386161%2C29.618547634531986%2C-98.61587524875381'
        )}
        {locationLink(
          'Portland',
          poImage,
          '?address=Portland%2C%20OR%2C%20USA&bounds=46.55120128970586%2C-121.18684640473747%2C44.75457072146682%2C-123.75723049058594'
        )}
        {locationLink(
          'Salt Lake City',
          slImage,
          '?address=Salt%20Lake%20City%2C%20UT%2C%20USA&bounds=41.7512852098358%2C-110.55178764096866%2C39.95465464159676%2C-112.92712852848516'
        )}
        {locationLink(
          'Chicago',
          chImage,
          '?address=Chicago%2C%20IL%2C%20USA&bounds=42.9214463018034%2C-86.31437862601541%2C41.12481573356436%2C-88.7329433240541'
        )}
        {locationLink(
          'Atlanta',
          alImage,
          '?address=Atlanta%2C%20GA%2C%20USA&bounds=34.78593316289061%2C-83.20723454277923%2C32.98930259465157%2C-85.37154341075033'
        )}
        {locationLink(
          'Boston',
          boImage,
          '?address=Boston%2C%20MA%2C%20USA&bounds=43.29913517199572%2C-69.53291873401287%2C41.502504603756684%2C-71.9659913048676'
        )}
        {locationLink(
          'Miami',
          miImage,
          '?address=Miami%2C%20FL%2C%20USA&bounds=26.754088247529413%2C-79.14090252937265%2C24.957457679290368%2C-81.13741155930941'
        )}
        {locationLink(
          'Philadelphia',
          piImage,
          '?address=Philadelphia%2C%20PA%2C%20USA&bounds=41.03630714831634%2C-73.78068330599993%2C39.2396765800773%2C-76.13084252009332'
        )}
        {locationLink(
          'Phoenix',
          phImage,
          '?address=Phoenix%2C%20AZ%2C%20USA&bounds=34.81888532150106%2C-110.84347315922787%2C33.02225475326202%2C-113.00861885169593'
        )}
        {locationLink(
          'Denver',
          coImage,
          '?address=Denver%2C%20CO%2C%20USA&bounds=40.81256222836993%2C-103.42906456506503%2C39.01593166013089%2C-105.77152728505996'
        )}
        {locationLink(
          'Nashville',
          naImage,
          '?address=Nashville%2C%20TN%2C%20USA&bounds=37.30381123250143%2C-85.39941725621385%2C35.50718066426239%2C-87.63175857158596'
        )}
        {locationLink(
          'Seattle',
          seImage,
          '?address=Seattle%2C%20WA%2C%20USA&bounds=48.63246033379419%2C-120.88872430773017%2C46.835829765555154%2C-123.56014198384364'
        )}
      </Carousel>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
