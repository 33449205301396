import React from 'react';
import { string } from 'prop-types';

const IconFind = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="60"
      width="60"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="#f19b8c" strokeLinejoin="round" strokeMiterlimit={10}>
        <path
          className="prefix__a"
          d="M16 22l-3.06-4.592c-1.215-1.855 1.789-2.48 3.17.612V10a1 1 0 012 0v4.754l3.963 1.146a1.284 1.284 0 01.894 1.522l-.085.367L22.117 22"
        />
        <path
          className="prefix__a"
          d="M11.1 17H2.6a1.5 1.5 0 01-1.5-1.5v-12A1.5 1.5 0 012.6 2h18a1.5 1.5 0 011.5 1.5V14M6.597 20h5m-1.5-3v3"
        />
      </g>
    </svg>
  );
};

IconFind.defaultProps = {
  className: null,
};

IconFind.propTypes = {
  className: string,
};

export default IconFind;
