import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDiscount.module.css';

const IconDiscount = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      height="47"
      viewBox="0 0 512.008 512.008"
      width="47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M189.998 323.484c2.63 0 5.21-1.06 7.07-2.93 1.86-1.86 2.93-4.43 2.93-7.07 0-2.63-1.07-5.21-2.93-7.07s-4.43-2.93-7.07-2.93c-2.63 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07c0 2.64 1.07 5.21 2.93 7.07 1.86 1.87 4.44 2.93 7.07 2.93z" />
      <path d="M427.83 237.035v-40.932H441c10.477 0 19-8.523 19-19V99.004c0-10.477-8.523-19-19-19h-62.345c12.924-12.677 20.962-30.318 20.962-49.808 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 18.683-10.345 34.987-25.603 43.509A49.688 49.688 0 00360 50.004c0-27.57-22.43-50-50-50-22.601 0-41.738 15.077-47.905 35.699-8.691-7.295-19.886-11.699-32.095-11.699s-23.404 4.404-32.095 11.699C191.738 15.081 172.601.004 150 .004c-27.57 0-50 22.43-50 50 0 8.571 2.17 16.644 5.986 23.701-15.258-8.522-25.603-24.827-25.603-43.51 0-5.523-4.477-10-10-10s-10 4.477-10 10c0 19.49 8.038 37.131 20.962 49.808H19c-10.477 0-19 8.523-19 19v78.099c0 10.477 8.523 19 19 19h13.17v245.901c0 16.542 13.458 30 30 30h206.34c25.874 24.764 60.939 40 99.498 40 79.402 0 144-64.598 144-144 0-58.068-34.555-108.211-84.178-130.968zM280 74.004v-24c0-16.542 13.458-30 30-30s30 13.458 30 30-13.458 30-30 30h-30zm0 26h160v76.099H280zm0 96.099h127.83v33.506a143.608 143.608 0 00-39.822-5.605c-33.117 0-63.654 11.244-88.008 30.106zM200 74.004c0-16.542 13.458-30 30-30s30 13.458 30 30v6h-60zm0 26h60v76.099h-60zm-50-80c16.542 0 30 13.458 30 30v30h-30c-16.542 0-30-13.458-30-30s13.458-30 30-30zM20 176.103v-76.099h160v76.099H42.17zm180 275.901v-93.516c0-5.523-4.477-10-10-10s-10 4.477-10 10v93.516H62.17c-5.514 0-10-4.486-10-10V196.103H180v72.385c0 5.523 4.477 10 10 10s10-4.477 10-10v-72.385h60v76.775c-22.387 25.388-35.992 58.696-35.992 95.126 0 31.325 10.06 60.341 27.11 84zm168.008 40c-68.374 0-124-55.626-124-124s55.626-124 124-124 124 55.626 124 124-55.626 124-124 124z" />
      <path d="M400.614 302.192a10 10 0 00-13.364 4.627l-55.024 113.306c-2.413 4.968-.341 10.951 4.627 13.364a9.962 9.962 0 004.361 1.007c3.709 0 7.273-2.073 9.003-5.634l55.024-113.306c2.413-4.968.341-10.952-4.627-13.364zM342.732 296.576c-6.045-6.045-14.083-9.374-22.631-9.374-8.549 0-16.586 3.329-22.631 9.374-12.479 12.479-12.479 32.784 0 45.263 6.24 6.24 14.434 9.359 22.631 9.359 8.195 0 16.393-3.121 22.632-9.359 12.477-12.479 12.477-32.784-.001-45.263zm-14.142 31.12c-4.681 4.682-12.298 4.681-16.978 0-4.681-4.681-4.681-12.297 0-16.979 2.267-2.268 5.282-3.516 8.489-3.516s6.221 1.249 8.489 3.517c4.681 4.681 4.681 12.298 0 16.978zM395.063 394.169c-12.479 12.479-12.479 32.784 0 45.263 6.045 6.045 14.083 9.374 22.631 9.374 8.549 0 16.586-3.329 22.631-9.374 12.479-12.479 12.479-32.784 0-45.263s-32.783-12.478-45.262 0zm31.12 31.121c-2.267 2.268-5.282 3.516-8.489 3.516s-6.221-1.249-8.489-3.517c-4.681-4.681-4.681-12.297 0-16.978 2.341-2.341 5.415-3.511 8.489-3.511s6.149 1.17 8.489 3.511c4.681 4.681 4.681 12.298 0 16.979z" />
    </svg>
  );
};

IconDiscount.defaultProps = {
  rootClassName: null,
  className: null,
};

IconDiscount.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconDiscount;
