import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './SignupForm.module.css';

const CustomCategorySelectFieldMaybe = props => {
  const { name, id, types, intl } = props;
  const userTypeLabel = intl.formatMessage({
    id: 'SignupForm.typeLabel',
  });
  const userTypePlaceholder = intl.formatMessage({
    id: 'SignupForm.typePlaceholder',
  });
  const userTypeRequired = required(
    intl.formatMessage({
      id: 'SignupForm.typeRequired',
    })
  );

  return types ? (
    <FieldSelect
      className={css.type}
      name={name}
      id={id}
      label={userTypeLabel}
      validate={userTypeRequired}
    >
      <option disabled value="">
        {userTypePlaceholder}
      </option>
      {types.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};
export default CustomCategorySelectFieldMaybe;
