import React, { Component } from 'react';
import { string } from 'prop-types';
import { InlineTextButton } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import truncate from 'lodash/truncate';

import css from './ExpandableText.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 50;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  render() {
    const { expand } = this.state;
    const { className, text } = this.props;
    const truncatedText = truncated(text);

    const handleShowMoreClick = () => {
      this.setState({ expand: true });
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="ExpandableText.showFullTextLink" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? text : truncatedText}
        {text !== truncatedText && !expand ? showMore : null}
      </p>
    );
  }
}

ExpandableText.defaultProps = { className: null };

ExpandableText.propTypes = {
  className: string,
  text: string.isRequired,
};

export default ExpandableText;
