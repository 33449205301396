import React, { useRef, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../components';
import { useHistory, withRouter } from 'react-router';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import axios from 'axios';

import css from './PayOrApplyCouponPage.module.css';

function PayOrApplyCouponPage(props) {
  const { currentListing, currentListingId } = props;

  const [couponError, setCouponError] = useState(null);
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponValue, setCouponValue] = useState(null);
  const couponRef = useRef(null);
  const history = useHistory();

  const redirectToListingPage = () => {
    return history.push({
      pathname: `/l/${createSlug(
        currentListing?.attributes?.title
      )}/${currentListingId}/draft/location/mini_session`,
      state: {
        publishable: true,
      },
    });
  };

  const handleCouponClick = async e => {
    setCouponError(null);
    setCouponLoading(true);
    e.preventDefault();
    const code = couponRef.current.value;
    try {
      await axios.post('/coupon', { code, currentListingId });
      redirectToListingPage();
    } catch (e) {
      setCouponError(e?.response?.data?.message);
    }
    setCouponLoading(false);
  };

  const handlePayViaCard = e => {
    e.preventDefault();
    return history.push({
      pathname: '/listing/create/mini_session/pay',
      state: { currentListingId },
    });
  };

  return (
    <div className={css.root}>
      <div>
        <h1 className={css.title}>
          <FormattedMessage id="PayOrApplyCouponPage.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="PayOrApplyCouponPage.subTitle" />
        </p>
        <div>
          <label>
            <FormattedMessage id="PayOrApplyCouponPage.couponLabel" />
          </label>
          <input
            type="text"
            ref={couponRef}
            className={css.input}
            autoComplete="off"
            placeholder="Write your unused code here"
            name="coupon"
            id="coupon"
            onChange={e => setCouponValue(e.target.value)}
          />
          {couponError && <div className={css.error}>{couponError}</div>}

          <PrimaryButton
            className={css.couponButton}
            onClick={handleCouponClick}
            disabled={couponLoading || !couponValue}
          >
            <FormattedMessage id="PayOrApplyCouponPage.applyButton" />
          </PrimaryButton>
        </div>

        <div className={css.dividerContainer}>
          <span className={css.divider}>
            <FormattedMessage id="PayOrApplyCouponPage.orDivider" />
          </span>
        </div>

        <SecondaryButton className={css.payButton} onClick={handlePayViaCard}>
          <FormattedMessage id="PayOrApplyCouponPage.payButton" />
        </SecondaryButton>
      </div>
    </div>
  );
}

export default withRouter(PayOrApplyCouponPage);
