import React from 'react';
import { string } from 'prop-types';

const IconPhotoshoot = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      height="60"
      width="60"
      {...props}
    >
      <g fill="none" stroke="#f19b8c" strokeLinejoin="round" strokeMiterlimit={10}>
        <path d="M7.018 17.75a8.952 8.952 0 00-1.518 5h18a9 9 0 00-9-9" />
        <path d="M12.5 14.75a1 1 0 01-1 1h-10a1 1 0 01-1-1v-6a1 1 0 011-1h4c.28-.56.451-1 1.118-1h2.264c.667 0 .838.44 1.118 1h1.5a1 1 0 011 1z" />
        <circle cx={7.5} cy={11.25} r={2} />
        <path d="M2 5.75h.5m12 6.5a5.5 5.5 0 10-5.119-7.5" />
      </g>
    </svg>
  );
};

IconPhotoshoot.defaultProps = {
  className: null,
};

IconPhotoshoot.propTypes = {
  className: string,
};

export default IconPhotoshoot;
