/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

// Import custom reviews images
import firstReviewImage from './assets/reviewsImages/image_firstReview.jpg';
import secondReviewImage from './assets/reviewsImages/image_secondReview.jpg';
import thirdReviewImage from './assets/reviewsImages/image_thirdReview.jpg';

// Import list of all large US cities
const cities = require('./cities.json');

export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['pub_date'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'photographer', label: 'Full Photography Session' },
        { key: 'mini_session', label: 'Mini Sessions' },
      ],
    },
  },
  {
    id: 'subCategory',
    label: 'Location',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subCategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'bay_area',
          label: 'Bay Area',
        },
        {
          key: 'san_diego',
          label: 'San Diego',
        },
        {
          key: 'los_angeles',
          label: 'Los Angeles',
        },
        {
          key: 'seattle',
          label: 'Seattle',
        },
        {
          key: 'maryland_dc_area',
          label: 'Maryland DC Area',
        },
        {
          key: 'minnesota',
          label: 'Minnesota',
        },
        {
          key: 'north_carolina',
          label: 'North Carolina',
        },
        {
          key: 'Michigan',
          label: 'Michigan',
        },
        { key: 'new_york', label: 'New York' },
        { key: 'austin', label: 'Austin' },
        { key: 'portland', label: 'Portland' },
        { key: 'salt-lake-city', label: 'Salt Lake City' },
        { key: 'chicago', label: 'Chicago' },
        { key: 'atlanta', label: 'Atlanta' },
        { key: 'boston', label: 'Boston' },
        { key: 'miami', label: 'Miami' },
        { key: 'philadelphia', label: 'Philadelphia' },
        { key: 'phoenix', label: 'Phoenix' },
        { key: 'denver', label: 'Denver' },
        { key: 'nashville', label: 'Nashville' },
        { key: 'memphis', label: 'Memphis' },
        { key: 'savannah', label: 'Savannah' },
        { key: 'fort-mayers', label: 'Fort Meyers' },
        { key: 'florida', label: 'Florida' },
      ],
    },
  },
  {
    id: 'cities',
    label: 'Location',
    type: 'LocationFilter',
    group: 'primary',
    queryParamNames: ['pub_subCategory'],
    scroll: true,
    hide: true,
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: cities.map(city => ({ key: city.key, label: city.label, value: city.label })),
    },
  },
  {
    id: 'address',
    label: 'Location',
    type: 'SearchLocationFilter',
    group: 'primary',
    queryParamNames: ['address'],
    scroll: true,
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [],
    },
  },
  {
    id: 'photographerServices',
    label: 'Type of Photoshoot',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_photographerServices'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'family_photography',
          label: 'Family photography',
        },
        {
          key: 'headshots',
          label: 'Headshots',
        },
        {
          key: 'maternity',
          label: 'Maternity',
        },
        {
          key: 'newborn',
          label: 'Newborn',
        },
        { key: 'engagements', label: 'Engagements' },
        { key: 'seniors', label: 'Seniors' },
        { key: 'underwater', label: 'Underwater' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    hide: true,
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    hide: true,
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'yogaStyles',
    label: 'Yoga styles',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    hide: true,
    queryParamNames: ['pub_yogaStyles'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'ashtanga', label: 'Ashtanga' },
        { key: 'hatha', label: 'Hatha' },
        { key: 'kundalini', label: 'Kundalini' },
        { key: 'restorative', label: 'Restorative' },
        { key: 'vinyasa', label: 'Vinyasa' },
        { key: 'yin', label: 'Yin' },
      ],
    },
  },
  {
    id: 'certificate',
    label: 'Certificate',
    type: 'SelectSingleFilter',
    group: 'secondary',
    hide: true,
    queryParamNames: ['pub_certificate'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
        { key: '200h', label: 'Registered yoga teacher 200h' },
        { key: '500h', label: 'Registered yoga teacher 500h' },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_timestamp', label: 'Random' },
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: '-pub_date', label: 'Nearest date' },
    { key: 'pub_date', label: 'Furthest date' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const miniSessionBookingOptions = [
  { key: 'ownWebsite', value: 'My website' },
  { key: 'currentWebsite', value: 'This website' },
];

export const listingCategories = [
  {
    key: 'photographer',
    label: 'Full Photography Session',
  },
  {
    key: 'mini_session',
    label: 'Mini Session',
  },
];

export const sessionLengthOptions = [
  { label: '10 minutes', key: 10 },
  { label: '15 minutes', key: 15 },
  { label: '20 minutes', key: 20 },
  { label: '25 minutes', key: 25 },
  { label: '30 minutes', key: 30 },
  { label: '35 minutes', key: 35 },
  { label: '40 minutes', key: 40 },
  { label: '45 minutes', key: 45 },
  { label: '50 minutes', key: 50 },
  { label: '55 minutes', key: 55 },
  { label: '60 minutes', key: 60 },
];

export const userType = [
  { key: 'client', label: 'Client' },
  { key: 'photographer', label: 'Photographer' },
];

export const landingPageReviewsIds = [
  '609bdf69-b422-41ea-bbdb-4ce28a38537b',
  '609bdf69-4fdf-49f5-823a-e0fdf1638416',
  '609bdf69-6ae5-4a4f-83bf-2715f0f30952',
];

export const landingPageReviews = [
  {
    content:
      'We had a terrific experience! Kristen was a total pro - efficient and knowledgeable, super easy to communicate and make arrangements with. And we LOVE our pictures... 100% recommend and we would happily book with her again in the future!',
    rating: 5,
    authorDisplayName: 'Megan',
    subjectDisplayName: 'by Kristen in the Bay Area',
    image: firstReviewImage,
  },
  {
    content:
      'We had a great session with Juliet! She was very flexible and easy going. She was quick to reply to messages and good with the kids. We got the perfect shot for our holiday card!',
    rating: 5,
    authorDisplayName: 'Bahia',
    subjectDisplayName: 'by Juliet in the Bay Area',
    image: secondReviewImage,
  },
  {
    content:
      'Everything went so smoothly! Khattab and his assistant were highly communicative, easy to work with, flexible about our health needs, and totally amazing with our four (wild) children. In short, he was beyond professional. We could not be happier with both the process and the results. Our family is so grateful we were able to capture this moment in time.',
    rating: 5,
    authorDisplayName: 'Lauren',
    subjectDisplayName: 'by Khattab in the Bay Area',
    image: thirdReviewImage,
  },
];

export const defaultMessages = [
  { key: 'how-many-people-are-in-group', content: 'How many people are in your group?' },
  {
    key: 'anything-extra',
    content: 'Anything extra you think your photographer should know about?',
  },
];

export const turnaroundTime = [
  { key: 'one-day', label: '1 day' },
  { key: 'two-days', label: '2 days' },
  { key: 'three-days', label: '3 days' },
  { key: 'four-days', label: '4 days' },
  { key: 'five-days', label: '5 days' },
  { key: 'one-week', label: '1 week' },
  { key: 'two-weeks', label: '2 weeks' },
  { key: 'three-weeks', label: '3 weeks' },
  { key: 'one-month', label: '1 month' },
  { key: 'two-months', label: '2 months' },
];
