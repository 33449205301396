import React from 'react';
import { ExternalLink } from '../../components';

import yelpBusinessImage from './supportImages/yelp_businessid.jpg';
import css from './FAQPage.module.css';

export const articles = [
  {
    key: 'cancellation-policy',
    title: 'What is the Picturehum cancellation policy?',
    html: (
      <div>
        <ul>
          <li>
            All photoshoot cancellations made more than 48-hours prior to scheduled photoshoot by
            Clients will be fully refunded.
          </li>
          <li>
            Photoshoots cancelled by a Client less than 48-hours from scheduled photoshoot, that are
            not rescheduled, will be paid in full to Photographer.
          </li>
          <li>
            Cancelled photoshoots can be rescheduled up to 60-days from originally scheduled
            photoshoot.
          </li>
          <li>
            Any photoshoot cancellations made by a Photographer will be fully refunded to the
            client.
          </li>
        </ul>
      </div>
    ),
  },

  {
    key: 'photoshoot-reschedule',
    title: 'Can my photoshoot be rescheduled due to weather or other unforeseen circumstances?',
    html: (
      <div>
        <p>
          As long as both parties agree the photoshoot can be rescheduled with no additional fee.
        </p>
      </div>
    ),
  },

  {
    key: 'receive-images',
    title: 'When will I receive my images?',
    html: (
      <div>
        <p>All images will be delivered in jpeg format within 5 days of the photoshoot.</p>
      </div>
    ),
  },

  {
    key: 'yelp-credentials',
    title: 'How can I get my Yelp account credentials?',
    html: (
      <div>
        <div>
          <p>
            As a part of your listing creation you have an option to fetch reviews from your Yelp
            listing which will then be added to the listing you are posting on our website. This
            will make your profile look more appealing when you are just starting and don't have any
            reviews posted on our marketplace.
          </p>

          <p>
            In order to do so you have to add your <strong>business ID</strong> so that we can get
            the reviews from your Yelp listing.
          </p>
        </div>

        <div>
          <h3>Getting the Business ID</h3>
          <ul>
            <li>You will find the Business ID on the Yelp’s Business URL.</li>
            <li>
              Observe that it will appear after www.yelp.com/biz/ in the address bar and it contains
              the name and location of the business separated by dashes.
            </li>
            <li>
              For example, the Business ID in the following URL{' '}
              <ExternalLink href="https://www.yelp.com/biz/sea-star-underwater-portraits-san-francisco">
                https://www.yelp.com/biz/sea-star-underwater-portraits-san-francisco
              </ExternalLink>{' '}
              is “<strong>sea-star-underwater-portraits-san-francisco</strong>“.
            </li>
          </ul>

          <div className={css.imageContainer}>
            <div className={css.imageWithCaption}>
              <img src={yelpBusinessImage} alt="Getting the Business ID" />
            </div>
            <span className={css.imageCaption}>Getting the Business ID</span>
          </div>
        </div>
      </div>
    ),
  },
];
